// // src/axiosConfig.js
// import axios from 'axios';
//
// const api = axios.create({
//     baseURL: 'http://127.0.0.1:5001'
// });
//
// // Add a request interceptor to add the token to all requests
// api.interceptors.request.use(
//     (config) => {
//         const token = localStorage.getItem('token');
//         if (token) {
//             config.headers.Authorization = `Bearer ${token}`;
//         }
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );
//
// // Add a response interceptor to handle errors
// api.interceptors.response.use(
//     (response) => response,
//     (error) => {
//         if (error.response?.status === 401) {
//             // Handle unauthorized errors (e.g., token expired)
//             localStorage.removeItem('token');
//             window.location.href = '/login';
//         }
//         return Promise.reject(error);
//     }
// );
//
// export default api;







// import axios from 'axios';
//
// const api = axios.create({
//     baseURL: process.env.REACT_APP_API_URL
// });
//
// api.interceptors.request.use(
//     (config) => {
//         const token = localStorage.getItem('token');
//         if (token) {
//             config.headers.Authorization = `Bearer ${token}`;
//         }
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );
//
// export default api;

// import axios from 'axios';
//
// const api = axios.create({
//     baseURL: process.env.REACT_APP_API_URL || 'http://127.0.0.1:5001'
// });
//
// api.interceptors.request.use(
//     (config) => {
//         const token = localStorage.getItem('token');
//         if (token) {
//             config.headers.Authorization = `Bearer ${token}`;
//         }
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );
//
// export default api;

// src/axiosConfig.js


// import axios from 'axios';
//
// const api = axios.create({
//     baseURL: process.env.REACT_APP_API_URL || 'http://127.0.0.1:5001',
//     withCredentials: true,
//     headers: {
//         'Content-Type': 'application/json',
//         'Accept': 'application/json'
//     }
// });
//
// // Fixed request interceptor
// api.interceptors.request.use(
//     (config) => {
//         const token = localStorage.getItem('token');
//
//         // If it's a Google login request and already has Authorization header, don't modify
//         if (config.url === '/login/google' && config.headers.Authorization) {
//             return config;
//         }
//
//         // For all other requests, add token if it exists
//         if (token) {
//             config.headers.Authorization = `Bearer ${token}`;
//         }
//
//         return config;
//     },
//     (error) => Promise.reject(error)
// );
//
// // Fixed response interceptor
// api.interceptors.response.use(
//     (response) => response,
//     (error) => {
//         console.error('API Error:', {
//             url: error.config?.url,
//             status: error.response?.status,
//             data: error.response?.data
//         });
//
//         if (error.response?.status === 401 || error.response?.status === 403) {
//             localStorage.removeItem('token');
//             window.location.href = '/login';
//         }
//
//         return Promise.reject(error);
//     }
// );
//
// export default api;

import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://127.0.0.1:5001',
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
});

// Request interceptor to handle authentication
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');

        // Special handling for file downloads
        if (config.url?.includes('/download/')) {
            config.responseType = 'blob';
            config.headers['Accept'] = 'text/csv';
            config.headers['Content-Type'] = 'text/csv';
        }

        // If it's a Google login request and already has Authorization header, don't modify
        if (config.url === '/login/google' && config.headers.Authorization) {
            return config;
        }

        // For multipart form data, remove Content-Type header to let browser set it
        if (config.headers['Content-Type'] === 'multipart/form-data') {
            delete config.headers['Content-Type'];
        }

        // For all other requests, add token if it exists
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => Promise.reject(error)
);

// Response interceptor to handle errors and authentication
api.interceptors.response.use(
    (response) => {
        // Handle successful downloads
        if (response.config.responseType === 'blob') {
            const contentType = response.headers['content-type'];
            if (contentType && contentType.includes('application/json')) {
                // If the response is JSON despite being requested as blob, it's probably an error
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        try {
                            const error = JSON.parse(reader.result);
                            reject(error);
                        } catch (e) {
                            reject({ error: 'Failed to parse error response' });
                        }
                    };
                    reader.onerror = () => reject({ error: 'Failed to read error response' });
                    reader.readAsText(response.data);
                });
            }
        }
        return response;
    },
    (error) => {
        // Log the error details
        console.error('API Error:', {
            url: error.config?.url,
            status: error.response?.status,
            data: error.response?.data,
            method: error.config?.method,
            headers: error.config?.headers
        });

        // Handle authentication errors
        if (error.response?.status === 401 || error.response?.status === 403) {
            localStorage.removeItem('token');
            if (!window.location.pathname.includes('/login')) {
                window.location.href = '/login';
            }
        }

        // Handle download errors
        if (error.config?.responseType === 'blob' && error.response?.data instanceof Blob) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    try {
                        const errorData = JSON.parse(reader.result);
                        error.response.data = errorData;
                        reject(error);
                    } catch (e) {
                        reject(error);
                    }
                };
                reader.onerror = () => reject(error);
                reader.readAsText(error.response.data);
            });
        }

        return Promise.reject(error);
    }
);

export default api;