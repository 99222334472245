// src/components/Login.js
// import React, { useState } from 'react';
// import axios from 'axios';
// import { Mail, Lock, ArrowRight } from 'lucide-react';
// import { Link, useNavigate } from 'react-router-dom';
//
// const Login = ({ setToken }) => {
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [error, setError] = useState(null);
//     const [loading, setLoading] = useState(false);
//     const navigate = useNavigate();
//
//     const handleLogin = async (e) => {
//         e.preventDefault();
//         setError(null);
//         setLoading(true);
//
//         try {
//             const response = await axios.post('http://127.0.0.1:5001/login', { email, password });
//             const accessToken = response.data.access_token;
//             localStorage.setItem('accessToken', accessToken);
//             setToken(accessToken);
//             navigate("/new-project");  // Navigate to dashboard after login
//         } catch (error) {
//             setError(error.response?.data?.error || "Invalid email or password.");
//         } finally {
//             setLoading(false);
//         }
//     };
//
//     return (
//         <div className="min-h-screen flex items-center justify-center bg-slate-50 p-4">
//             <div className="bg-white rounded-xl shadow-sm p-8 w-full max-w-md">
//                 <div className="text-center mb-8">
//                     <h2 className="text-2xl font-bold text-slate-800">Welcome Back</h2>
//                     <p className="text-slate-500 mt-2">Please enter your details</p>
//                 </div>
//
//                 <form onSubmit={handleLogin} className="space-y-4">
//                     <div>
//                         <label className="block text-sm font-medium text-slate-700 mb-1">Email</label>
//                         <div className="relative">
//                             <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//                                 <Mail className="h-5 w-5 text-slate-400" />
//                             </div>
//                             <input
//                                 type="email"
//                                 value={email}
//                                 onChange={(e) => setEmail(e.target.value)}
//                                 className="w-full pl-10 pr-4 py-2 rounded-lg border border-slate-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                                 required
//                             />
//                         </div>
//                     </div>
//
//                     <div>
//                         <label className="block text-sm font-medium text-slate-700 mb-1">Password</label>
//                         <div className="relative">
//                             <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//                                 <Lock className="h-5 w-5 text-slate-400" />
//                             </div>
//                             <input
//                                 type="password"
//                                 value={password}
//                                 onChange={(e) => setPassword(e.target.value)}
//                                 className="w-full pl-10 pr-4 py-2 rounded-lg border border-slate-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                                 required
//                             />
//                         </div>
//                     </div>
//
//                     {error && (
//                         <div className="bg-red-50 text-red-500 p-3 rounded-lg text-sm">
//                             {error}
//                         </div>
//                     )}
//
//                     <button
//                         type="submit"
//                         disabled={loading}
//                         className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center justify-center"
//                     >
//                         {loading ? (
//                             <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />
//                         ) : (
//                             <>
//                                 Sign In
//                                 <ArrowRight className="ml-2 h-5 w-5" />
//                             </>
//                         )}
//                     </button>
//                 </form>
//
//                 <div className="mt-6 text-center">
//                     <p className="text-slate-600">
//                         Don’t have an account?{" "}
//                         <Link to="/signup" className="text-blue-600 hover:text-blue-700 font-medium">
//                             Sign up
//                         </Link>
//                     </p>
//                 </div>
//             </div>
//         </div>
//     );
// };
//
// export default Login;

// src/components/Login.js
// import React, { useState } from 'react';
// import axios from 'axios';
// import { Mail, Lock, ArrowRight } from 'lucide-react';
// import { Link, useNavigate } from 'react-router-dom';
// import { GoogleLogin } from '@react-oauth/google';
//
// const Login = ({ setToken }) => {
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [error, setError] = useState(null);
//     const [loading, setLoading] = useState(false);
//     const navigate = useNavigate();
//
//     const handleLogin = async (e) => {
//         e.preventDefault();
//         setError(null);
//         setLoading(true);
//
//         try {
//             const response = await axios.post('http://127.0.0.1:5001/login', { email, password });
//             setToken(response.data.access_token);
//             navigate("/new-project");
//         } catch (error) {
//             setError(error.response?.data?.error || "Invalid email or password.");
//         } finally {
//             setLoading(false);
//         }
//     };
//
//     const handleGoogleSuccess = async (credentialResponse) => {
//         try {
//             const { data } = await axios.post(
//                 'http://127.0.0.1:5001/login/google',
//                 {},  // empty body
//                 {
//                     headers: {
//                         'Authorization': `Bearer ${credentialResponse.credential}`
//                     }
//                 }
//             );
//             setToken(data.access_token);
//             navigate("/new-project");
//         } catch (error) {
//             console.error('Google login error:', error);
//             setError("Google login failed. Please try again.");
//         }
//     };
//
//     const handleGoogleFailure = () => {
//         setError("Google login was unsuccessful. Please try again.");
//     };
//
//     return (
//         <div className="min-h-screen flex items-center justify-center bg-slate-50 p-4">
//             <div className="bg-white rounded-xl shadow-sm p-8 w-full max-w-md">
//                 <div className="text-center mb-8">
//                     <h2 className="text-2xl font-bold text-slate-800">Welcome Back</h2>
//                     <p className="text-slate-500 mt-2">Please enter your details</p>
//                 </div>
//
//                 <form onSubmit={handleLogin} className="space-y-4">
//                     <div>
//                         <label className="block text-sm font-medium text-slate-700 mb-1">Email</label>
//                         <div className="relative">
//                             <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//                                 <Mail className="h-5 w-5 text-slate-400"/>
//                             </div>
//                             <input
//                                 type="email"
//                                 value={email}
//                                 onChange={(e) => setEmail(e.target.value)}
//                                 className="w-full pl-10 pr-4 py-2 rounded-lg border border-slate-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                                 required
//                             />
//                         </div>
//                     </div>
//
//                     <div>
//                         <label className="block text-sm font-medium text-slate-700 mb-1">Password</label>
//                         <div className="relative">
//                             <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//                                 <Lock className="h-5 w-5 text-slate-400"/>
//                             </div>
//                             <input
//                                 type="password"
//                                 value={password}
//                                 onChange={(e) => setPassword(e.target.value)}
//                                 className="w-full pl-10 pr-4 py-2 rounded-lg border border-slate-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                                 required
//                             />
//                         </div>
//                     </div>
//
//                     {error && (
//                         <div className="bg-red-50 text-red-500 p-3 rounded-lg text-sm">
//                             {error}
//                         </div>
//                     )}
//
//                     <button
//                         type="submit"
//                         disabled={loading}
//                         className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center justify-center"
//                     >
//                         {loading ? (
//                             <div
//                                 className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin"/>
//                         ) : (
//                             <>
//                                 Sign In
//                                 <ArrowRight className="ml-2 h-5 w-5"/>
//                             </>
//                         )}
//                     </button>
//                 </form>
//
//                 {/* Google Login */}
//                 <div className="my-4 text-center">
//                     <p className="text-sm text-slate-600">Or log in with</p>
//                     <GoogleLogin onSuccess={handleGoogleSuccess} onFailure={handleGoogleFailure}/>
//                 </div>
//
//                 <div className="mt-6 text-center">
//                     <p>Don’t have an account? <Link to="/signup">Sign up</Link></p>
//                 </div>
//             </div>
//         </div>
//     );
// };
//
// export default Login;

import React, { useState, useEffect } from 'react';
import { Mail, Lock, ArrowRight } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import api from '../axiosConfig';

const Login = ({ setToken }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // Check for existing token on component mount
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setToken(token);
            navigate("/new-project");
        }
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        try {
            // Log the request data
            console.log('Login attempt with:', { email, password });

            const response = await api.post('/login', {
                email,
                password
            });

            console.log('Login response:', response.data);

            if (response.data.access_token) {
                localStorage.setItem('token', response.data.access_token);
                setToken(response.data.access_token);
                navigate("/new-project");
            } else {
                setError('Invalid response from server');
            }
        } catch (error) {
            // Enhanced error logging
            console.error('Login error full details:', {
                response: error.response?.data,
                status: error.response?.status,
                message: error.message,
                data: error.response?.data
            });

            setError(error.response?.data?.error || "Login failed. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleSuccess = async (credentialResponse) => {
        try {
            console.log('Google credential full response:', credentialResponse);

            const response = await api.post(
                '/login/google',
                {},
                {
                    headers: {
                        'Authorization': `Bearer ${credentialResponse.credential}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    withCredentials: true
                }
            );

            console.log('Google login server response:', response.data);

            if (response.data.access_token) {
                localStorage.setItem('token', response.data.access_token);
                setToken(response.data.access_token);
                navigate("/new-project");
            } else {
                throw new Error('Invalid response from server');
            }
        } catch (error) {
            console.error('Google login error full details:', {
                response: error.response?.data,
                status: error.response?.status,
                message: error.message,
                data: error.response?.data
            });

            setError(error.response?.data?.error || "Google login failed");
        }
    };

    const handleGoogleFailure = (error) => {
        console.error('Google login failure:', error); // Debug log
        setError("Google login was unsuccessful. Please try again.");
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-slate-50 p-4">
            <div className="bg-white rounded-xl shadow-sm p-8 w-full max-w-md">
                <div className="text-center mb-8">
                    <h2 className="text-2xl font-bold text-slate-800">Welcome Back</h2>
                    <p className="text-slate-500 mt-2">Please enter your details</p>
                </div>

                <form onSubmit={handleLogin} className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium text-slate-700 mb-1">Email</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <Mail className="h-5 w-5 text-slate-400"/>
                            </div>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full pl-10 pr-4 py-2 rounded-lg border border-slate-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                placeholder="Enter your email"
                                required
                            />
                        </div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-slate-700 mb-1">Password</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <Lock className="h-5 w-5 text-slate-400"/>
                            </div>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="w-full pl-10 pr-4 py-2 rounded-lg border border-slate-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                placeholder="Enter your password"
                                required
                            />
                        </div>
                    </div>

                    {error && (
                        <div className="bg-red-50 text-red-500 p-3 rounded-lg text-sm">
                            {error}
                        </div>
                    )}

                    <button
                        type="submit"
                        disabled={loading}
                        className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center justify-center"
                    >
                        {loading ? (
                            <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin"/>
                        ) : (
                            <>
                                Sign In
                                <ArrowRight className="ml-2 h-5 w-5"/>
                            </>
                        )}
                    </button>
                </form>

                {/* Google Login */}
                <div className="my-4 text-center">
                    <p className="text-sm text-slate-600">Or log in with</p>
                    <div className="mt-2">
                        <GoogleLogin
                            onSuccess={handleGoogleSuccess}
                            onError={handleGoogleFailure}  // Using the error handler here
                            useOneTap={false}
                            theme="filled_blue"
                            shape="rectangular"
                            context="signin"
                        />
                    </div>
                </div>

                <div className="mt-6 text-center">
                    <p className="text-slate-600">
                        Don't have an account?{' '}
                        <Link to="/signup" className="text-blue-600 hover:text-blue-700 font-medium">
                            Sign up
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Login;