// // // src/components/Sidebar.js
// // import React from 'react';
// //
// // const Sidebar = ({ setView }) => {
// //     return (
// //         <div className="bg-gray-800 h-screen p-4 text-white w-1/5">
// //             <ul>
// //                 <li className="mb-4 cursor-pointer" onClick={() => setView("New Project")}>
// //                     New Project
// //                 </li>
// //                 <li className="mb-4 cursor-pointer" onClick={() => setView("Projects")}>
// //                     Projects
// //                 </li>
// //                 <li className="mb-4 cursor-pointer" onClick={() => setView("Credits")}>
// //                     Credits
// //                 </li>
// //                 <li className="mb-4 cursor-pointer" onClick={() => setView("Account")}>
// //                     Account
// //                 </li>
// //             </ul>
// //         </div>
// //     );
// // };
// //
// // export default Sidebar;
//
// // import React, { useState } from 'react';
// // import { FolderPlus, ListTodo, CreditCard, UserCircle } from 'lucide-react';
// //
// // const Sidebar = ({ setView }) => {
// //     const [activeView, setActiveView] = useState('New Project');
// //
// //     const handleViewChange = (view) => {
// //         setActiveView(view);
// //         setView(view);
// //     };
// //
// //     const menuItems = [
// //         { id: 1, title: 'New Project', icon: FolderPlus },
// //         { id: 2, title: 'Projects', icon: ListTodo },
// //         { id: 3, title: 'Credits', icon: CreditCard },
// //         { id: 4, title: 'Account', icon: UserCircle }
// //     ];
// //
// //     return (
// //         <div className="bg-slate-900 h-screen w-64 p-4 flex flex-col">
// //             {/* Logo/Brand Area */}
// //             <div className="mb-8 px-2">
// //                 <div className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-violet-400 text-transparent bg-clip-text">
// //                     Persoline
// //                 </div>
// //             </div>
// //
// //             {/* Navigation Menu */}
// //             <nav className="flex-1">
// //                 <ul className="space-y-2">
// //                     {menuItems.map((item) => {
// //                         const Icon = item.icon;
// //                         const isActive = activeView === item.title;
// //
// //                         return (
// //                             <li key={item.id}>
// //                                 <button
// //                                     onClick={() => handleViewChange(item.title)}
// //                                     className={`w-full px-3 py-3 rounded-lg flex items-center gap-3 transition-all duration-200 group
// //                                         ${isActive
// //                                         ? 'bg-gradient-to-r from-blue-500/10 to-violet-500/10 text-white'
// //                                         : 'text-slate-400 hover:bg-slate-800 hover:text-white'
// //                                     }`}
// //                                 >
// //                                     <Icon
// //                                         className={`w-5 h-5 transition-colors duration-200
// //                                             ${isActive
// //                                             ? 'text-blue-400'
// //                                             : 'text-slate-400 group-hover:text-white'
// //                                         }`}
// //                                     />
// //                                     <span className="font-medium">{item.title}</span>
// //
// //                                     {/* Active Indicator */}
// //                                     {isActive && (
// //                                         <div className="ml-auto w-1.5 h-1.5 rounded-full bg-gradient-to-r from-blue-400 to-violet-400" />
// //                                     )}
// //                                 </button>
// //                             </li>
// //                         );
// //                     })}
// //                 </ul>
// //             </nav>
// //
// //             {/* Footer Area */}
// //             <div className="mt-auto">
// //                 <div className="border-t border-slate-800 pt-4">
// //                     <div className="px-3 py-2 text-xs text-slate-400">
// //                         Version 1.0.0
// //                     </div>
// //                 </div>
// //             </div>
// //         </div>
// //     );
// // };
// //
// // export default Sidebar;
//
// // Sidebar.js
// import React from 'react';
// import { FolderPlus, ListTodo, CreditCard, UserCircle, LogOut } from 'lucide-react';
//
// const Sidebar = ({ setView, view }) => {
//     const menuItems = [
//         { id: 1, title: 'New Project', icon: FolderPlus },
//         { id: 2, title: 'Projects', icon: ListTodo },
//         { id: 3, title: 'Credits', icon: CreditCard },
//         { id: 4, title: 'Account', icon: UserCircle }
//     ];
//
//     const handleLogout = () => {
//         localStorage.removeItem('accessToken'); // Clear the token
//         window.location.reload(); // Reload to redirect to login screen
//     };
//
//     return (
//         <div className="bg-slate-900 h-screen w-64 p-4 flex flex-col">
//             {/* Logo/Brand Area */}
//             <div className="mb-8 px-2">
//                 <div className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-violet-400 text-transparent bg-clip-text">
//                     Persoline
//                 </div>
//             </div>
//
//             {/* Navigation Menu */}
//             <nav className="flex-1">
//                 <ul className="space-y-2">
//                     {menuItems.map((item) => {
//                         const Icon = item.icon;
//                         const isActive = view === item.title;
//
//                         return (
//                             <li key={item.id}>
//                                 <button
//                                     onClick={() => setView(item.title)}
//                                     className={`w-full px-3 py-3 rounded-lg flex items-center gap-3 transition-all duration-200 group
//                                         ${isActive
//                                         ? 'bg-gradient-to-r from-blue-500/10 to-violet-500/10 text-white'
//                                         : 'text-slate-400 hover:bg-slate-800 hover:text-white'
//                                     }`}
//                                 >
//                                     <Icon
//                                         className={`w-5 h-5 transition-colors duration-200
//                                             ${isActive
//                                             ? 'text-blue-400'
//                                             : 'text-slate-400 group-hover:text-white'
//                                         }`}
//                                     />
//                                     <span className="font-medium">{item.title}</span>
//
//                                     {/* Active Indicator */}
//                                     {isActive && (
//                                         <div className="ml-auto w-1.5 h-1.5 rounded-full bg-gradient-to-r from-blue-400 to-violet-400" />
//                                     )}
//                                 </button>
//                             </li>
//                         );
//                     })}
//                 </ul>
//             </nav>
//
//             {/* Logout Button */}
//             <div className="mt-auto">
//                 <button
//                     onClick={handleLogout}
//                     className="w-full px-3 py-3 rounded-lg flex items-center gap-3 text-slate-400 hover:bg-slate-800 hover:text-white transition-all duration-200"
//                 >
//                     <LogOut className="w-5 h-5 text-red-400" />
//                     <span className="font-medium text-red-400">Logout</span>
//                 </button>
//             </div>
//         </div>
//     );
// };
//
// export default Sidebar;

// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import { FolderPlus, ListTodo, CreditCard, UserCircle, LogOut } from 'lucide-react';
//
// const Sidebar = () => {
//     const navigate = useNavigate();  // Initialize navigation
//
//     const menuItems = [
//         { id: 1, title: 'New Project', icon: FolderPlus, path: '/new-project' },
//         { id: 2, title: 'Projects', icon: ListTodo, path: '/projects' },
//         { id: 3, title: 'Credits', icon: CreditCard, path: '/credits' },
//         { id: 4, title: 'Account', icon: UserCircle, path: '/account' },
//     ];
//
//     // Logout function
//     const handleLogout = () => {
//         localStorage.removeItem('accessToken');  // Remove token
//         navigate('/login');  // Redirect to login page
//     };
//
//     return (
//         <div className="bg-slate-900 h-screen w-64 p-4 flex flex-col">
//             {/* Logo/Brand Area */}
//             <div className="mb-8 px-2">
//                 <div className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-violet-400 text-transparent bg-clip-text">
//                     Persoline
//                 </div>
//             </div>
//
//             {/* Navigation Menu */}
//             <nav className="flex-1">
//                 <ul className="space-y-2">
//                     {menuItems.map((item) => {
//                         const Icon = item.icon;
//                         return (
//                             <li key={item.id}>
//                                 <button
//                                     onClick={() => navigate(item.path)}
//                                     className="w-full px-3 py-3 rounded-lg flex items-center gap-3 transition-all duration-200 group text-slate-400 hover:bg-slate-800 hover:text-white"
//                                 >
//                                     <Icon className="w-5 h-5" />
//                                     <span className="font-medium">{item.title}</span>
//                                 </button>
//                             </li>
//                         );
//                     })}
//                 </ul>
//             </nav>
//
//             {/* Logout Button */}
//             <button
//                 onClick={handleLogout}
//                 className="w-full px-3 py-3 mt-4 text-red-500 hover:bg-red-600 hover:text-white rounded-lg flex items-center gap-3 transition-all duration-200"
//             >
//                 <LogOut className="w-5 h-5" />
//                 <span className="font-medium">Logout</span>
//             </button>
//         </div>
//     );
// };
//
// export default Sidebar;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import {FolderPlus, ListTodo, CreditCard, UserCircle, LogOut, HelpCircle, Receipt} from 'lucide-react';

const Sidebar = ({ handleLogout: parentHandleLogout }) => {
    const navigate = useNavigate();

    const menuItems = [
        { id: 1, title: 'New Project', icon: FolderPlus, path: '/new-project' },
        { id: 2, title: 'Projects', icon: ListTodo, path: '/projects' },
        { id: 3, title: 'Credits', icon: CreditCard, path: '/credits' },
        { id: 4, title: 'Support', icon: HelpCircle, path: '/support' },
        { id: 5, title: 'Invoices', icon: Receipt, path: '/invoices' },
        { id: 6, title: 'Account', icon: UserCircle, path: '/account' },
    ];

    const handleLogout = () => {
        localStorage.removeItem('token');
        if (parentHandleLogout) {
            parentHandleLogout();
        }
        navigate('/login');
    };

    const handleNavigation = (path) => {
        const token = localStorage.getItem('token');
        if (!token) {
            handleLogout();
            return;
        }
        navigate(path);
    };

    return (
        <div className="w-64 h-screen flex flex-col bg-slate-800">
            {/* Logo/Brand Area */}
            <div className="p-4 border-b border-slate-700">
                <div className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-violet-400 text-transparent bg-clip-text">
                    Persoline
                </div>
            </div>

            {/* Navigation Menu */}
            <div className="flex-1">
                <nav className="px-2 py-4">
                    <ul className="space-y-2">
                        {menuItems.map((item) => {
                            const Icon = item.icon;
                            return (
                                <li key={item.id}>
                                    <button
                                        onClick={() => handleNavigation(item.path)}
                                        className="w-full px-3 py-3 rounded-lg flex items-center gap-3 transition-all duration-200 group text-slate-400 hover:bg-slate-700 hover:text-white"
                                    >
                                        <Icon className="w-5 h-5" />
                                        <span className="font-medium">{item.title}</span>
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                </nav>
            </div>

            {/* Logout Button */}
            <div className="p-4 border-t border-slate-700">
                <button
                    onClick={handleLogout}
                    className="w-full px-3 py-3 text-red-500 hover:bg-red-600 hover:text-white rounded-lg flex items-center gap-3 transition-all duration-200"
                >
                    <LogOut className="w-5 h-5" />
                    <span className="font-medium">Logout</span>
                </button>
            </div>
        </div>
    );
};

export default Sidebar;