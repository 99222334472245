// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// test///fredss/jdcdc
// const Account = () => {
//     const [tokenBalance, setTokenBalance] = useState(0);
//     const [transactionHistory, setTransactionHistory] = useState([]);
//     const [userDetails, setUserDetails] = useState({ email: '', full_name: '' });
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//
//     useEffect(() => {
//         const fetchAccountData = async () => {
//             try {
//                 const headers = {
//                     Authorization: `Bearer ${localStorage.getItem('accessToken')}`
//                 };
//
//                 // Fetch token balance
//                 const balanceResponse = await axios.get('http://127.0.0.1:5001/token_balance', { headers });
//                 setTokenBalance(balanceResponse.data.token_balance);
//
//                 // Fetch transaction history
//                 const historyResponse = await axios.get('http://127.0.0.1:5001/transaction_history', { headers });
//                 setTransactionHistory(historyResponse.data.transaction_history);
//
//                 // Fetch user details
//                 const userResponse = await axios.get('http://127.0.0.1:5001/dashboard', { headers });
//                 setUserDetails({ email: userResponse.data.email, full_name: userResponse.data.full_name });
//             } catch (error) {
//                 setError("Failed to load account information.");
//             } finally {
//                 setLoading(false);
//             }
//         };
//
//         fetchAccountData();
//     }, []);
//
//     if (loading) {
//         return <p>Loading account information...</p>;
//     }
//
//     if (error) {
//         return <p className="text-red-500">{error}</p>;
//     }
//
//     return (
//         <div className="bg-white p-6 shadow rounded-lg">
//             <h2 className="text-3xl font-bold mb-4">Account</h2>
//
//             {/* Profile Information */}
//             <div className="mb-4">
//                 <h3 className="text-2xl font-semibold mb-2">Profile Information</h3>
//                 <p>Name: {userDetails.full_name}</p>
//                 <p>Email: {userDetails.email}</p>
//             </div>
//
//             {/* Token Balance */}
//             <div className="mb-4">
//                 <h3 className="text-xl font-semibold">Token Balance: {tokenBalance}</h3>
//             </div>
//
//             {/* Transaction History */}
//             <div className="mt-6">
//                 <h3 className="text-2xl font-semibold mb-2">Transaction History</h3>
//                 {transactionHistory.length === 0 ? (
//                     <p>No transactions yet.</p>
//                 ) : (
//                     <table className="table-auto w-full border-collapse">
//                         <thead>
//                         <tr>
//                             <th className="px-4 py-2 border-b">Type</th>
//                             <th className="px-4 py-2 border-b">Amount</th>
//                             <th className="px-4 py-2 border-b">Balance After</th>
//                             <th className="px-4 py-2 border-b">Date</th>
//                         </tr>
//                         </thead>
//                         <tbody>
//                         {transactionHistory.map((transaction, index) => (
//                             <tr key={index} className="text-center">
//                                 <td className="px-4 py-2 border-b capitalize">{transaction.transaction_type}</td>
//                                 <td className="px-4 py-2 border-b">{transaction.amount}</td>
//                                 <td className="px-4 py-2 border-b">{transaction.balance_after}</td>
//                                 <td className="px-4 py-2 border-b">
//                                     {new Date(transaction.timestamp).toLocaleString()}
//                                 </td>
//                             </tr>
//                         ))}
//                         </tbody>
//                     </table>
//                 )}
//             </div>
//
//             {/* Buy Tokens Button */}
//             <div className="mt-6">
//                 <button
//                     onClick={() => window.location.href = '/credits'}
//                     className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
//                 >
//                     Buy More Tokens
//                 </button>
//             </div>
//         </div>
//     );
// };
//
// export default Account;

// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import {
//     User, Mail, Coins, ArrowUpRight, ArrowDownRight,
//     Clock, Wallet, Loader2, AlertCircle, CreditCard
// } from 'lucide-react';
//
// const Account = ({ setView }) => {
//     const [tokenBalance, setTokenBalance] = useState(0);
//     const [transactionHistory, setTransactionHistory] = useState([]);
//     const [userDetails, setUserDetails] = useState({ email: '', full_name: '' });
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//
//     useEffect(() => {
//         const fetchAccountData = async () => {
//             try {
//                 const headers = {
//                     Authorization: `Bearer ${localStorage.getItem('accessToken')}`
//                 };
//
//                 const [balanceResponse, historyResponse, userResponse] = await Promise.all([
//                     axios.get('http://127.0.0.1:5001/token_balance', { headers }),
//                     axios.get('http://127.0.0.1:5001/transaction_history', { headers }),
//                     axios.get('http://127.0.0.1:5001/dashboard', { headers })
//                 ]);
//
//                 setTokenBalance(balanceResponse.data.token_balance);
//                 setTransactionHistory(historyResponse.data.transaction_history);
//                 setUserDetails({ email: userResponse.data.email, full_name: userResponse.data.full_name });
//             } catch (error) {
//                 setError("Failed to load account information.");
//             } finally {
//                 setLoading(false);
//             }
//         };
//
//         fetchAccountData();
//     }, []);
//
//     const formatDate = (dateString) => {
//         const date = new Date(dateString);
//         const day = String(date.getDate()).padStart(2, '0');
//         const month = String(date.getMonth() + 1).padStart(2, '0');
//         const year = date.getFullYear();
//         const hours = String(date.getHours()).padStart(2, '0');
//         const minutes = String(date.getMinutes()).padStart(2, '0');
//
//         return `${day}.${month}.${year}, ${hours}:${minutes}`;
//     };
//
//     const TransactionIcon = ({ type }) => {
//         const styles = {
//             purchase: "text-green-500 bg-green-50",
//             usage: "text-red-500 bg-red-50"
//         };
//
//         return type === 'purchase' ? (
//             <div className={`p-2 rounded-full ${styles[type]}`}>
//                 <ArrowUpRight className="w-4 h-4" />
//             </div>
//         ) : (
//             <div className={`p-2 rounded-full ${styles[type]}`}>
//                 <ArrowDownRight className="w-4 h-4" />
//             </div>
//         );
//     };
//
//     if (loading) {
//         return (
//             <div className="flex items-center justify-center h-screen">
//                 <div className="flex flex-col items-center gap-4">
//                     <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
//                     <p className="text-slate-600 font-medium">Loading account information...</p>
//                 </div>
//             </div>
//         );
//     }
//
//     if (error) {
//         return (
//             <div className="flex items-center justify-center h-screen">
//                 <div className="flex items-center gap-3 text-red-500">
//                     <AlertCircle className="w-6 h-6" />
//                     <p className="font-medium">{error}</p>
//                 </div>
//             </div>
//         );
//     }
//
//     return (
//         <div className="max-w-4xl mx-auto p-6 space-y-6">
//             {/* Profile Card */}
//             <div className="bg-white rounded-xl shadow-sm p-6">
//                 <h2 className="text-2xl font-bold text-slate-800 mb-6">Account Details</h2>
//                 <div className="grid md:grid-cols-2 gap-6">
//                     <div className="flex items-center gap-4">
//                         <div className="p-3 bg-blue-50 rounded-lg">
//                             <User className="w-6 h-6 text-blue-500" />
//                         </div>
//                         <div>
//                             <p className="text-sm text-slate-500">Full Name</p>
//                             <p className="font-medium text-slate-800">{userDetails.full_name}</p>
//                         </div>
//                     </div>
//                     <div className="flex items-center gap-4">
//                         <div className="p-3 bg-purple-50 rounded-lg">
//                             <Mail className="w-6 h-6 text-purple-500" />
//                         </div>
//                         <div>
//                             <p className="text-sm text-slate-500">Email</p>
//                             <p className="font-medium text-slate-800">{userDetails.email}</p>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//
//             {/* Token Balance Card */}
//             <div className="bg-gradient-to-br from-blue-500 to-violet-500 rounded-xl shadow-sm p-6 text-white">
//                 <div className="flex justify-between items-start">
//                     <div>
//                         <p className="text-white/80 mb-1 flex items-center gap-2">
//                             <Coins className="w-5 h-5"/>
//                             Available Balance
//                         </p>
//                         <h3 className="text-3xl font-bold">{tokenBalance.toLocaleString()}</h3>
//                     </div>
//                     <button
//                         onClick={() => setView("Credits")}  // Use setView here
//                         className="bg-white text-blue-600 px-4 py-2 rounded-lg font-medium hover:bg-blue-50 transition-colors duration-150 flex items-center gap-2"
//                     >
//                         <CreditCard className="w-4 h-4"/>
//                         Buy Tokens
//                     </button>
//                 </div>
//             </div>
//
//             {/* Transaction History */}
//             <div className="bg-white rounded-xl shadow-sm p-6">
//                 <div className="flex justify-between items-center mb-6">
//                     <h3 className="text-xl font-bold text-slate-800 flex items-center gap-2">
//                         <Clock className="w-5 h-5"/>
//                         Transaction History
//                     </h3>
//                     <span className="text-sm text-slate-500">
//                         Total Transactions: {transactionHistory.length}
//                     </span>
//                 </div>
//
//                 {transactionHistory.length === 0 ? (
//                     <div className="flex flex-col items-center justify-center py-12 text-slate-500">
//                         <Wallet className="w-12 h-12 mb-4"/>
//                         <p className="text-lg font-medium">No transactions yet</p>
//                         <p className="text-sm mt-2">Your transaction history will appear here</p>
//                     </div>
//                 ) : (
//                     <div className="max-h-[400px] overflow-y-auto scrollbar-thin scrollbar-thumb-slate-200 scrollbar-track-slate-50">
//                         <table className="w-full">
//                             <thead className="bg-slate-50 sticky top-0">
//                             <tr>
//                                 <th className="px-6 py-4 text-left text-sm font-medium text-slate-600">Type</th>
//                                 <th className="px-6 py-4 text-right text-sm font-medium text-slate-600">Amount</th>
//                                 <th className="px-6 py-4 text-right text-sm font-medium text-slate-600">Balance</th>
//                                 <th className="px-6 py-4 text-right text-sm font-medium text-slate-600">Date</th>
//                             </tr>
//                             </thead>
//                             <tbody className="divide-y divide-slate-100">
//                             {transactionHistory.map((transaction, index) => (
//                                 <tr key={index} className="group hover:bg-slate-50 transition-colors duration-150">
//                                     <td className="px-6 py-4">
//                                         <div className="flex items-center gap-3">
//                                             <TransactionIcon type={transaction.transaction_type} />
//                                             <span className="font-medium text-slate-700 capitalize">
//                                                     {transaction.transaction_type}
//                                                 </span>
//                                         </div>
//                                     </td>
//                                     <td className="px-6 py-4 text-right">
//                                             <span className={`font-medium ${
//                                                 transaction.transaction_type === 'purchase'
//                                                     ? 'text-green-600'
//                                                     : 'text-red-600'
//                                             }`}>
//                                                 {transaction.transaction_type === 'purchase' ? '+' : '-'}
//                                                 {transaction.amount}
//                                             </span>
//                                     </td>
//                                     <td className="px-6 py-4 text-right">
//                                             <span className="text-slate-600">
//                                                 {transaction.balance_after}
//                                             </span>
//                                     </td>
//                                     <td className="px-6 py-4 text-right">
//                                             <span className="text-slate-500 text-sm">
//                                                 {formatDate(new Date(transaction.timestamp).toLocaleString())}
//                                             </span>
//                                     </td>
//                                 </tr>
//                             ))}
//                             </tbody>
//                         </table>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// };
//
// export default Account;

// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import {
//     User, Mail, Coins, ArrowUpRight, ArrowDownRight,
//     Clock, Wallet, Loader2, AlertCircle, CreditCard
// } from 'lucide-react';
//
// const Account = () => {
//     const [tokenBalance, setTokenBalance] = useState(0);
//     const [transactionHistory, setTransactionHistory] = useState([]);
//     const [userDetails, setUserDetails] = useState({ email: '', full_name: '' });
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const navigate = useNavigate();  // Initialize useNavigate for programmatic navigation
//
//     useEffect(() => {
//         const fetchAccountData = async () => {
//             try {
//                 const headers = {
//                     Authorization: `Bearer ${localStorage.getItem('accessToken')}`
//                 };
//
//                 const [balanceResponse, historyResponse, userResponse] = await Promise.all([
//                     axios.get('http://127.0.0.1:5001/token_balance', { headers }),
//                     axios.get('http://127.0.0.1:5001/transaction_history', { headers }),
//                     axios.get('http://127.0.0.1:5001/dashboard', { headers })
//                 ]);
//
//                 setTokenBalance(balanceResponse.data.token_balance);
//                 setTransactionHistory(historyResponse.data.transaction_history);
//                 setUserDetails({ email: userResponse.data.email, full_name: userResponse.data.full_name });
//             } catch (error) {
//                 setError("Failed to load account information.");
//             } finally {
//                 setLoading(false);
//             }
//         };
//
//         fetchAccountData();
//     }, []);
//
//     const formatDate = (dateString) => {
//         const date = new Date(dateString);
//         const day = String(date.getDate()).padStart(2, '0');
//         const month = String(date.getMonth() + 1).padStart(2, '0');
//         const year = date.getFullYear();
//         const hours = String(date.getHours()).padStart(2, '0');
//         const minutes = String(date.getMinutes()).padStart(2, '0');
//
//         return `${day}.${month}.${year}, ${hours}:${minutes}`;
//     };
//
//     const TransactionIcon = ({ type }) => {
//         const styles = {
//             purchase: "text-green-500 bg-green-50",
//             usage: "text-red-500 bg-red-50"
//         };
//
//         return type === 'purchase' ? (
//             <div className={`p-2 rounded-full ${styles[type]}`}>
//                 <ArrowUpRight className="w-4 h-4" />
//             </div>
//         ) : (
//             <div className={`p-2 rounded-full ${styles[type]}`}>
//                 <ArrowDownRight className="w-4 h-4" />
//             </div>
//         );
//     };
//
//     if (loading) {
//         return (
//             <div className="flex items-center justify-center h-screen">
//                 <div className="flex flex-col items-center gap-4">
//                     <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
//                     <p className="text-slate-600 font-medium">Loading account information...</p>
//                 </div>
//             </div>
//         );
//     }
//
//     if (error) {
//         return (
//             <div className="flex items-center justify-center h-screen">
//                 <div className="flex items-center gap-3 text-red-500">
//                     <AlertCircle className="w-6 h-6" />
//                     <p className="font-medium">{error}</p>
//                 </div>
//             </div>
//         );
//     }
//
//     return (
//         <div className="max-w-4xl mx-auto p-6 space-y-6">
//             {/* Profile Card */}
//             <div className="bg-white rounded-xl shadow-sm p-6">
//                 <h2 className="text-2xl font-bold text-slate-800 mb-6">Account Details</h2>
//                 <div className="grid md:grid-cols-2 gap-6">
//                     <div className="flex items-center gap-4">
//                         <div className="p-3 bg-blue-50 rounded-lg">
//                             <User className="w-6 h-6 text-blue-500" />
//                         </div>
//                         <div>
//                             <p className="text-sm text-slate-500">Full Name</p>
//                             <p className="font-medium text-slate-800">{userDetails.full_name}</p>
//                         </div>
//                     </div>
//                     <div className="flex items-center gap-4">
//                         <div className="p-3 bg-purple-50 rounded-lg">
//                             <Mail className="w-6 h-6 text-purple-500" />
//                         </div>
//                         <div>
//                             <p className="text-sm text-slate-500">Email</p>
//                             <p className="font-medium text-slate-800">{userDetails.email}</p>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//
//             {/* Token Balance Card */}
//             <div className="bg-gradient-to-br from-blue-500 to-violet-500 rounded-xl shadow-sm p-6 text-white">
//                 <div className="flex justify-between items-start">
//                     <div>
//                         <p className="text-white/80 mb-1 flex items-center gap-2">
//                             <Coins className="w-5 h-5"/>
//                             Available Balance
//                         </p>
//                         <h3 className="text-3xl font-bold">{tokenBalance.toLocaleString()}</h3>
//                     </div>
//                     <button
//                         onClick={() => navigate("/credits")}  // Navigate to Credits page
//                         className="bg-white text-blue-600 px-4 py-2 rounded-lg font-medium hover:bg-blue-50 transition-colors duration-150 flex items-center gap-2"
//                     >
//                         <CreditCard className="w-4 h-4"/>
//                         Buy Tokens
//                     </button>
//                 </div>
//             </div>
//
//             {/* Transaction History */}
//             <div className="bg-white rounded-xl shadow-sm p-6">
//                 <div className="flex justify-between items-center mb-6">
//                     <h3 className="text-xl font-bold text-slate-800 flex items-center gap-2">
//                         <Clock className="w-5 h-5"/>
//                         Transaction History
//                     </h3>
//                     <span className="text-sm text-slate-500">
//                         Total Transactions: {transactionHistory.length}
//                     </span>
//                 </div>
//
//                 {transactionHistory.length === 0 ? (
//                     <div className="flex flex-col items-center justify-center py-12 text-slate-500">
//                         <Wallet className="w-12 h-12 mb-4"/>
//                         <p className="text-lg font-medium">No transactions yet</p>
//                         <p className="text-sm mt-2">Your transaction history will appear here</p>
//                     </div>
//                 ) : (
//                     <div className="max-h-[400px] overflow-y-auto scrollbar-thin scrollbar-thumb-slate-200 scrollbar-track-slate-50">
//                         <table className="w-full">
//                             <thead className="bg-slate-50 sticky top-0">
//                             <tr>
//                                 <th className="px-6 py-4 text-left text-sm font-medium text-slate-600">Type</th>
//                                 <th className="px-6 py-4 text-right text-sm font-medium text-slate-600">Amount</th>
//                                 <th className="px-6 py-4 text-right text-sm font-medium text-slate-600">Balance</th>
//                                 <th className="px-6 py-4 text-right text-sm font-medium text-slate-600">Date</th>
//                             </tr>
//                             </thead>
//                             <tbody className="divide-y divide-slate-100">
//                             {transactionHistory.map((transaction, index) => (
//                                 <tr key={index} className="group hover:bg-slate-50 transition-colors duration-150">
//                                     <td className="px-6 py-4">
//                                         <div className="flex items-center gap-3">
//                                             <TransactionIcon type={transaction.transaction_type} />
//                                             <span className="font-medium text-slate-700 capitalize">
//                                                 {transaction.transaction_type}
//                                             </span>
//                                         </div>
//                                     </td>
//                                     <td className="px-6 py-4 text-right">
//                                         <span className={`font-medium ${
//                                             transaction.transaction_type === 'purchase'
//                                                 ? 'text-green-600'
//                                                 : 'text-red-600'
//                                         }`}>
//                                             {transaction.transaction_type === 'purchase' ? '+' : '-'}
//                                             {transaction.amount}
//                                         </span>
//                                     </td>
//                                     <td className="px-6 py-4 text-right">
//                                         <span className="text-slate-600">
//                                             {transaction.balance_after}
//                                         </span>
//                                     </td>
//                                     <td className="px-6 py-4 text-right">
//                                         <span className="text-slate-500 text-sm">
//                                             {formatDate(new Date(transaction.timestamp).toLocaleString())}
//                                         </span>
//                                     </td>
//                                 </tr>
//                             ))}
//                             </tbody>
//                         </table>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// };
//
// export default Account;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../axiosConfig';  // Update this path based on your file structure
import {
    User, Mail, Coins, ArrowUpRight, ArrowDownRight,
    Clock, Wallet, Loader2, AlertCircle, CreditCard
} from 'lucide-react';

const Account = () => {
    const [tokenBalance, setTokenBalance] = useState(0);
    const [transactionHistory, setTransactionHistory] = useState([]);
    const [userDetails, setUserDetails] = useState({ email: '', full_name: '' });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAccountData = async () => {
            try {
                setLoading(true);
                setError(null);

                const token = localStorage.getItem('token');
                if (!token) {
                    navigate('/login');
                    return;
                }

                const [balanceResponse, historyResponse, userResponse] = await Promise.all([
                    api.get('/token_balance'),
                    api.get('/transaction_history'),
                    api.get('/dashboard')
                ]);

                setTokenBalance(balanceResponse.data.token_balance || 0);
                setTransactionHistory(historyResponse.data.transaction_history || []);
                setUserDetails({
                    email: userResponse.data.email || '',
                    full_name: userResponse.data.full_name || ''
                });
            } catch (error) {
                console.error('Error fetching account data:', {
                    message: error.message,
                    response: error.response?.data,
                    status: error.response?.status
                });

                if (error.response?.status === 401 || error.response?.status === 403) {
                    localStorage.removeItem('token');
                    navigate('/login');
                } else {
                    setError(error.response?.data?.error || "Failed to load account information. Please try again later.");
                }
            } finally {
                setLoading(false);
            }
        };

        fetchAccountData();
    }, [navigate]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${day}.${month}.${year}, ${hours}:${minutes}`;
    };

    const TransactionIcon = ({ type }) => {
        const styles = {
            purchase: "text-green-500 bg-green-50",
            usage: "text-red-500 bg-red-50"
        };

        return type === 'purchase' ? (
            <div className={`p-2 rounded-full ${styles[type]}`}>
                <ArrowUpRight className="w-4 h-4" />
            </div>
        ) : (
            <div className={`p-2 rounded-full ${styles[type]}`}>
                <ArrowDownRight className="w-4 h-4" />
            </div>
        );
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="flex flex-col items-center gap-4">
                    <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
                    <p className="text-slate-600 font-medium">Loading account information...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="flex items-center gap-3 text-red-500">
                    <AlertCircle className="w-6 h-6" />
                    <p className="font-medium">{error}</p>
                </div>
            </div>
        );
    }

    return (
        <div className="max-w-4xl mx-auto p-6 space-y-6">
            {/* Profile Card */}
            <div className="bg-white rounded-xl shadow-sm p-6">
                <h2 className="text-2xl font-bold text-slate-800 mb-6">Account Details</h2>
                <div className="grid md:grid-cols-2 gap-6">
                    <div className="flex items-center gap-4">
                        <div className="p-3 bg-blue-50 rounded-lg">
                            <User className="w-6 h-6 text-blue-500" />
                        </div>
                        <div>
                            <p className="text-sm text-slate-500">Full Name</p>
                            <p className="font-medium text-slate-800">{userDetails.full_name}</p>
                        </div>
                    </div>
                    <div className="flex items-center gap-4">
                        <div className="p-3 bg-purple-50 rounded-lg">
                            <Mail className="w-6 h-6 text-purple-500" />
                        </div>
                        <div>
                            <p className="text-sm text-slate-500">Email</p>
                            <p className="font-medium text-slate-800">{userDetails.email}</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Token Balance Card */}
            <div className="bg-gradient-to-br from-blue-500 to-violet-500 rounded-xl shadow-sm p-6 text-white">
                <div className="flex justify-between items-start">
                    <div>
                        <p className="text-white/80 mb-1 flex items-center gap-2">
                            <Coins className="w-5 h-5"/>
                            Available Balance
                        </p>
                        <h3 className="text-3xl font-bold">{tokenBalance.toLocaleString()}</h3>
                    </div>
                    <button
                        onClick={() => navigate("/credits")}
                        className="bg-white text-blue-600 px-4 py-2 rounded-lg font-medium hover:bg-blue-50 transition-colors duration-150 flex items-center gap-2"
                    >
                        <CreditCard className="w-4 h-4"/>
                        Buy Tokens
                    </button>
                </div>
            </div>

            {/* Transaction History */}
            <div className="bg-white rounded-xl shadow-sm p-6">
                <div className="flex justify-between items-center mb-6">
                    <h3 className="text-xl font-bold text-slate-800 flex items-center gap-2">
                        <Clock className="w-5 h-5"/>
                        Transaction History
                    </h3>
                    <span className="text-sm text-slate-500">
                        Total Transactions: {transactionHistory.length}
                    </span>
                </div>

                {transactionHistory.length === 0 ? (
                    <div className="flex flex-col items-center justify-center py-12 text-slate-500">
                        <Wallet className="w-12 h-12 mb-4"/>
                        <p className="text-lg font-medium">No transactions yet</p>
                        <p className="text-sm mt-2">Your transaction history will appear here</p>
                    </div>
                ) : (
                    <div className="max-h-[400px] overflow-y-auto scrollbar-thin scrollbar-thumb-slate-200 scrollbar-track-slate-50">
                        <table className="w-full">
                            <thead className="bg-slate-50 sticky top-0">
                            <tr>
                                <th className="px-6 py-4 text-left text-sm font-medium text-slate-600">Type</th>
                                <th className="px-6 py-4 text-right text-sm font-medium text-slate-600">Amount</th>
                                <th className="px-6 py-4 text-right text-sm font-medium text-slate-600">Balance</th>
                                <th className="px-6 py-4 text-right text-sm font-medium text-slate-600">Date</th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-slate-100">
                            {transactionHistory.map((transaction, index) => (
                                <tr key={index} className="group hover:bg-slate-50 transition-colors duration-150">
                                    <td className="px-6 py-4">
                                        <div className="flex items-center gap-3">
                                            <TransactionIcon type={transaction.transaction_type} />
                                            <span className="font-medium text-slate-700 capitalize">
                                                    {transaction.transaction_type}
                                                </span>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 text-right">
                                            <span className={`font-medium ${
                                                transaction.transaction_type === 'purchase'
                                                    ? 'text-green-600'
                                                    : 'text-red-600'
                                            }`}>
                                                {transaction.transaction_type === 'purchase' ? '+' : '-'}
                                                {transaction.amount}
                                            </span>
                                    </td>
                                    <td className="px-6 py-4 text-right">
                                            <span className="text-slate-600">
                                                {transaction.balance_after}
                                            </span>
                                    </td>
                                    <td className="px-6 py-4 text-right">
                                            <span className="text-slate-500 text-sm">
                                                {formatDate(transaction.timestamp)}
                                            </span>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Account;