// // src/components/Header.js
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
//
// const Header = () => {
//     const [tokenBalance, setTokenBalance] = useState(0);
//
//     const fetchTokenBalance = async () => {
//         try {
//             const response = await axios.get('http://127.0.0.1:5001/token_balance', {
//                 headers: {
//                     Authorization: `Bearer ${localStorage.getItem('accessToken')}`  // Ensure user is logged in and token is saved
//                 }
//             });
//             setTokenBalance(response.data.token_balance);
//         } catch (error) {
//             console.error("Error fetching token balance:", error);
//         }
//     };
//
//     useEffect(() => {
//         fetchTokenBalance();
//     }, []);
//
//     return (
//         <div className="bg-gray-100 p-4 shadow-md flex justify-end">
//             <span className="text-lg font-semibold">Tokens: {tokenBalance}</span>
//         </div>
//     );
// };
//
// export default Header;


import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Coins } from 'lucide-react';
import api from '../axiosConfig';  // Update this path based on your file structure

const TokenDisplay = () => {
    const [tokenBalance, setTokenBalance] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const fetchTokenBalance = async () => {
        try {
            const response = await api.get('/token_balance');
            setTokenBalance(response.data.token_balance);
            setError(null);
        } catch (error) {
            console.error("Error fetching token balance:", error);
            if (error.response?.status === 401) {
                // Token is invalid or expired
                localStorage.removeItem('token');
                navigate('/login');
            } else {
                setError("Failed to load balance");
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        // Initial fetch
        fetchTokenBalance();

        // Set up interval for periodic refresh
        const interval = setInterval(fetchTokenBalance, 60000); // Refresh every minute

        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, [navigate]);

    if (error) {
        return (
            <div className="fixed top-4 right-4 z-50">
                <div className="bg-red-50 text-red-600 backdrop-blur-sm shadow-lg rounded-full py-2 px-4 flex items-center gap-2 border border-red-200">
                    <span className="text-sm">{error}</span>
                </div>
            </div>
        );
    }

    return (
        <div className="fixed top-4 right-4 z-50">
            <div className="bg-white/90 backdrop-blur-sm shadow-lg rounded-full py-2 px-4 flex items-center gap-2 border border-slate-200">
                {isLoading ? (
                    <div className="animate-pulse flex items-center gap-2">
                        <div className="w-5 h-5 rounded-full bg-slate-200" />
                        <div className="w-16 h-4 bg-slate-200 rounded" />
                    </div>
                ) : (
                    <>
                        <Coins className="w-5 h-5 text-blue-500" />
                        <span className="font-medium bg-gradient-to-r from-blue-600 to-violet-600 text-transparent bg-clip-text">
                            {tokenBalance.toLocaleString()}
                        </span>
                    </>
                )}
            </div>
        </div>
    );
};

export default TokenDisplay;