import React, { useState, useEffect } from 'react';
import { Receipt, Loader2, AlertCircle, Download } from 'lucide-react';
import api from '../axiosConfig';

const Invoices = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [invoices, setInvoices] = useState([]);

    useEffect(() => {
        fetchInvoices();
    }, []);

    const fetchInvoices = async () => {
        try {
            setLoading(true);
            const response = await api.get('/invoices');
            setInvoices(response.data.invoices);
        } catch (error) {
            console.error('Error fetching invoices:', error);
            setError('Failed to load invoices');
        } finally {
            setLoading(false);
        }
    };

    const handleDownload = async (invoiceId) => {
        try {
            const response = await api.get(`/download_invoice/${invoiceId}`, {
                responseType: 'blob'
            });

            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `invoice.pdf`);

            // Append to html page
            document.body.appendChild(link);

            // Force download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('Download error:', error);
            setError('Failed to download invoice');
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}.${month}.${year}, ${hours}:${minutes}`;
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="flex flex-col items-center gap-4">
                    <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
                    <p className="text-slate-600 font-medium">Loading invoices...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="flex items-center gap-3 text-red-500">
                    <AlertCircle className="w-6 h-6" />
                    <p className="font-medium">{error}</p>
                </div>
            </div>
        );
    }

    return (
        <div className="bg-white rounded-xl shadow-sm p-6 max-w-6xl mx-auto">
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold text-slate-800">Invoices</h2>
                <div className="text-sm text-slate-500">
                    Total Invoices: {invoices.length}
                </div>
            </div>

            {invoices.length === 0 ? (
                <div className="flex flex-col items-center justify-center py-12 text-slate-500">
                    <Receipt className="w-12 h-12 mb-4" />
                    <p className="text-lg font-medium">No invoices yet</p>
                    <p className="text-sm mt-2">Purchase tokens to see your invoices here</p>
                </div>
            ) : (
                <div className="max-h-[calc(100vh-12rem)] overflow-y-auto scrollbar-thin scrollbar-thumb-slate-200 scrollbar-track-slate-50">
                    <table className="w-full border-collapse">
                        <thead className="bg-slate-50 sticky top-0">
                        <tr>
                            <th className="px-6 py-4 text-left text-sm font-medium text-slate-600">Invoice #</th>
                            <th className="px-6 py-4 text-left text-sm font-medium text-slate-600">Date</th>
                            <th className="px-6 py-4 text-right text-sm font-medium text-slate-600">Amount</th>
                            <th className="px-6 py-4 text-right text-sm font-medium text-slate-600">Tokens</th>
                            <th className="px-6 py-4 text-right text-sm font-medium text-slate-600">Actions</th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-slate-100">
                        {invoices.map((invoice) => (
                            <tr
                                key={invoice._id}
                                className="group hover:bg-slate-50 transition-colors duration-150"
                            >
                                <td className="px-6 py-4">
                                    <div className="flex items-center gap-3">
                                        <Receipt className="w-5 h-5 text-slate-400" />
                                        <span className="font-medium text-slate-700">
                                                {invoice.invoice_number}
                                            </span>
                                    </div>
                                </td>
                                <td className="px-6 py-4 text-slate-600">
                                    {formatDate(invoice.date)}
                                </td>
                                <td className="px-6 py-4 text-right text-slate-600">
                                    ${invoice.amount.toFixed(2)}
                                </td>
                                <td className="px-6 py-4 text-right text-slate-600">
                                    {invoice.tokens.toLocaleString()}
                                </td>
                                <td className="px-6 py-4 text-right">
                                    <button
                                        onClick={() => handleDownload(invoice._id)}
                                        className="text-blue-600 hover:text-blue-700 p-2 rounded-lg hover:bg-blue-50 transition-colors duration-150"
                                        title="Download Invoice"
                                    >
                                        <Download className="w-5 h-5" />
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default Invoices;