// import React, {useState, useRef, useMemo, useEffect} from 'react';
// import { useNavigate } from 'react-router-dom';
// import api from '../axiosConfig';  // Update this path based on your file structure
// import Papa from 'papaparse';
//
// const NewProject = () => {
//     const [file, setFile] = useState(null);
//     const [columnNames, setColumnNames] = useState([]);
//     const [selectedColumns, setSelectedColumns] = useState({
//         personalLinkedIn: '',
//         companyLinkedIn: '',
//         websiteURL: '',
//         city: ''
//     });
//     const [error, setError] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [downloadLink, setDownloadLink] = useState(null);
//     const [tokenCost, setTokenCost] = useState(0);
//     const [confirmationOpen, setConfirmationOpen] = useState(false);
//     const [isDragging, setIsDragging] = useState(false);
//     const [touchedFields, setTouchedFields] = useState({});
//     const fileInputRef = useRef(null);
//     const [rowCount, setRowCount] = useState(0);
//     const navigate = useNavigate();
//     const [batchJobId, setBatchJobId] = useState(null);
//     const [batchStatus, setBatchStatus] = useState(null);
//     const [processedRows, setProcessedRows] = useState(0);
//     const [statusCheckInterval, setStatusCheckInterval] = useState(null);
//     const [processingMethod, setProcessingMethod] = useState('immediate');
//
//     const formatEstimatedTime = (seconds) => {
//         if (seconds < 60) {
//             return `${seconds} seconds`;
//         } else if (seconds < 3600) {
//             const minutes = Math.ceil(seconds / 60);
//             return `${minutes} minute${minutes > 1 ? 's' : ''}`;
//         } else {
//             const hours = Math.ceil(seconds / 3600);
//             return `${hours} hour${hours > 1 ? 's' : ''}`;
//         }
//     };
//
//     const estimatedTime = useMemo(() => {
//         const secondsPerRow = 15;
//         const totalSeconds = rowCount * secondsPerRow;
//         return formatEstimatedTime(totalSeconds);
//     }, [rowCount]);
//
//     const handleFileChange = (e) => {
//         const selectedFile = e.target.files[0];
//         if (selectedFile) {
//             // Add console logs to verify file content
//             console.log('Selected file:', selectedFile);
//
//             // Create a FileReader to verify file content
//             const reader = new FileReader();
//             reader.onload = (event) => {
//                 console.log('File content preview:', event.target.result.slice(0, 200));
//             };
//             reader.readAsText(selectedFile);
//
//             handleFileSelection(selectedFile);
//         }
//     };
//     // Calculate batch savings (25% discount, rounded up in favor of user)
//     const calculateBatchSavings = (tokens) => {
//         const savings = Math.ceil(tokens * 0.25);
//         const discountedCost = tokens - savings;
//         return {
//             savings,
//             discountedCost
//         };
//     };
//
//     // Modify the existing confirmation dialog
//     const BatchConfirmationDialog = ({ onConfirm, onCancel, tokenCost }) => {
//         const showBatchOption = tokenCost > 10;
//         const { savings, discountedCost } = calculateBatchSavings(tokenCost);
//
//         return (
//             <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
//                 <div className="bg-white rounded-xl p-6 max-w-md w-full">
//                     <h3 className="text-xl font-semibold mb-4">Choose Processing Method</h3>
//
//                     {showBatchOption ? (
//                         <div className="space-y-4 mb-6">
//                             <div className="space-y-2">
//                                 <label className="flex items-start space-x-3">
//                                     <input
//                                         type="radio"
//                                         name="processingMethod"
//                                         value="immediate"
//                                         checked={processingMethod === 'immediate'}
//                                         onChange={(e) => setProcessingMethod(e.target.value)}
//                                         className="mt-1"
//                                     />
//                                     <div>
//                                         <p className="font-medium">Immediate Processing</p>
//                                         <p className="text-sm text-gray-600">Process now for {tokenCost} tokens</p>
//                                     </div>
//                                 </label>
//                             </div>
//
//                             <div className="space-y-2">
//                                 <label className="flex items-start space-x-3">
//                                     <input
//                                         type="radio"
//                                         name="processingMethod"
//                                         value="batch"
//                                         checked={processingMethod === 'batch'}
//                                         onChange={(e) => setProcessingMethod(e.target.value)}
//                                         className="mt-1"
//                                     />
//                                     <div>
//                                         <div className="font-medium flex items-center space-x-2">
//                                             <span>Batch Processing</span>
//                                             <span className="px-2 py-0.5 bg-green-100 text-green-800 text-xs rounded-full">
//                                                 Save {savings} tokens
//                                             </span>
//                                         </div>
//                                         <p className="text-sm text-gray-600">
//                                             Process within 24 hours for {discountedCost} tokens
//                                         </p>
//                                     </div>
//                                 </label>
//                             </div>
//
//                             <div className="bg-blue-50 p-3 rounded-lg text-sm text-blue-800">
//                                 💡 Batch processing can save you {savings} tokens by processing your request
//                                 during off-peak hours. Your results will be ready within 24 hours.
//                             </div>
//                         </div>
//                     ) : (
//                         <p className="text-gray-600 mb-6">
//                             This job will deduct <span className="font-semibold text-blue-600">{tokenCost} tokens</span> from your account.
//                         </p>
//                     )}
//
//                     <div className="flex space-x-4">
//                         <button
//                             onClick={() => onConfirm(processingMethod)}
//                             className="flex-1 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
//                         >
//                             Confirm
//                         </button>
//                         <button
//                             onClick={onCancel}
//                             className="flex-1 px-4 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 transition-colors duration-200"
//                         >
//                             Cancel
//                         </button>
//                     </div>
//                 </div>
//             </div>
//         );
//     };
//
//     const handleFileSelection = (selectedFile) => {
//         if (selectedFile) {
//             setFile(selectedFile);
//             Papa.parse(selectedFile, {
//                 header: true,
//                 skipEmptyLines: true, // Skip empty lines
//                 complete: (results) => {
//                     // Filter out empty rows
//                     const validRows = results.data.filter(row => {
//                         // Check if row has any non-empty values
//                         return Object.values(row).some(value => value && value.trim() !== '');
//                     });
//
//                     setColumnNames(results.meta.fields);
//                     setTokenCost(validRows.length);
//                     setRowCount(validRows.length);
//                     setTouchedFields({});
//
//                     console.log(`Total rows: ${results.data.length}, Valid rows: ${validRows.length}`);
//                 },
//                 error: (error) => {
//                     setError("Error parsing file: " + error.message);
//                 }
//             });
//         }
//     };
//
//     const handleDragOver = (e) => {
//         e.preventDefault();
//         setIsDragging(true);
//     };
//
//     const handleDragLeave = (e) => {
//         e.preventDefault();
//         setIsDragging(false);
//     };
//
//     const handleDrop = (e) => {
//         e.preventDefault();
//         setIsDragging(false);
//         const droppedFile = e.dataTransfer.files[0];
//         handleFileSelection(droppedFile);
//     };
//
//     const handleColumnSelect = (field, value) => {
//         setSelectedColumns((prev) => ({ ...prev, [field]: value }));
//         setTouchedFields((prev) => ({ ...prev, [field]: true }));
//     };
//
//     const handleColumnBlur = (field) => {
//         setTouchedFields((prev) => ({ ...prev, [field]: true }));
//     };
//
//     const areAllColumnsSelected = Object.values(selectedColumns).every(col => col);
//
//     // Update the initiateUpload function
//     const initiateUpload = () => {
//         const allTouched = Object.keys(selectedColumns).reduce((acc, field) => {
//             acc[field] = true;
//             return acc;
//         }, {});
//         setTouchedFields(allTouched);
//
//         if (!areAllColumnsSelected) {
//             setError("Please select all required columns.");
//             return;
//         }
//         setError('');
//         setConfirmationOpen(true);
//     };
//
//     // Modify the handleUpload function
//     const handleUpload = async (method) => {
//         setLoading(true);
//         setConfirmationOpen(false);
//
//         const formData = new FormData();
//         formData.append('file', file);
//         formData.append('processingMethod', method);
//
//         // Log debug information
//         console.log('File being uploaded:', file);
//         console.log('Column mappings:', selectedColumns);
//
//         Object.keys(selectedColumns).forEach((key) => {
//             formData.append(key, selectedColumns[key]);
//             console.log(`Appending ${key}:`, selectedColumns[key]);
//         });
//
//         try {
//             const response = await api.post('/upload_project', formData, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data',
//                 }
//             });
//
//             // Handle immediate processing success
//             if (response.data.output_file_url) {
//                 setDownloadLink(response.data.output_file_url);
//                 setLoading(false);
//                 setError('');
//             }
//             // Handle batch processing
//             else if (response.data.batch_job_id) {
//                 setBatchJobId(response.data.batch_job_id);
//                 startStatusChecking(response.data.batch_job_id);
//                 setError('');
//             } else {
//                 setError('Invalid response from server');
//                 setLoading(false);
//             }
//         } catch (error) {
//             console.error('Upload error details:', {
//                 message: error.message,
//                 response: error.response?.data,
//                 file: file?.name,
//                 fileSize: file?.size,
//                 formData: Object.fromEntries(formData.entries())
//             });
//
//             if (error.response?.status === 401) {
//                 localStorage.removeItem('token');
//                 navigate('/login');
//             } else if (error.response?.status === 403 && error.response?.data.error.includes("Insufficient tokens")) {
//                 const confirm = window.confirm("You don't have enough tokens. Would you like to buy more credits?");
//                 if (confirm) {
//                     navigate("/credits");
//                 }
//             } else {
//                 setError(error.response?.data?.error || 'Error uploading file. Please try again.');
//             }
//             setLoading(false);
//         }
//     };
//     const startStatusChecking = (jobId) => {
//         if (!jobId) {
//             console.error('No job ID provided');
//             setError('Invalid batch job ID');
//             return;
//         }
//
//         // Clear any existing interval
//         if (statusCheckInterval) {
//             clearInterval(statusCheckInterval);
//         }
//
//         // Start new interval to check status every 10 seconds
//         const interval = setInterval(async () => {
//             try {
//                 console.log('Checking status for job:', jobId); // Add this for debugging
//                 const response = await api.get(`/batch_status/${jobId}`);
//
//                 console.log('Status response:', response.data); // Add this for debugging
//
//                 setBatchStatus(response.data.status);
//                 setProcessedRows(response.data.processed_rows || 0);
//
//                 if (['completed', 'failed'].includes(response.data.status)) {
//                     clearInterval(interval);
//                     setLoading(false);
//                     if (response.data.status === 'completed') {
//                         setDownloadLink(response.data.output_file_url);
//                     } else if (response.data.status === 'failed') {
//                         setError(response.data.error || 'Processing failed');
//                     }
//                 }
//             } catch (error) {
//                 console.error('Status check error:', error);
//                 clearInterval(interval);
//                 setLoading(false);
//                 setError('Error checking processing status');
//             }
//         }, 60000); // Check every 10 seconds
//
//         setStatusCheckInterval(interval);
//     };
//
//     useEffect(() => {
//         return () => {
//             if (statusCheckInterval) {
//                 clearInterval(statusCheckInterval);
//             }
//         };
//     }, [statusCheckInterval]);
//
//     // Check if the form should be disabled
//     const isFormDisabled = loading || downloadLink !== null;
//
//     const handleDownloadNewProject = async (downloadLink) => {
//         try {
//             // Log the download attempt
//             console.log('Attempting download with link:', downloadLink);
//
//             // Make sure we're using the full URL
//             const fullUrl = downloadLink.startsWith('http')
//                 ? downloadLink
//                 : `${api.defaults.baseURL}${downloadLink}`;
//
//             const response = await api.get(fullUrl, {
//                 responseType: 'blob',
//                 headers: {
//                     'Accept': 'text/csv',
//                 }
//             });
//
//             // Check if we got a valid response
//             if (!response.data || response.data.size === 0) {
//                 throw new Error('Received empty file');
//             }
//
//             // Get filename from Content-Disposition header if available
//             const contentDisposition = response.headers['content-disposition'];
//             let filename = 'processed_file.csv';
//             if (contentDisposition) {
//                 const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/g.exec(contentDisposition);
//                 if (matches && matches[1]) {
//                     filename = matches[1].replace(/['"]/g, '');
//                 }
//             }
//
//             // Create and trigger download
//             const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
//             const link = document.createElement('a');
//             link.href = url;
//             link.setAttribute('download', filename);
//             document.body.appendChild(link);
//             link.click();
//
//             // Cleanup
//             window.URL.revokeObjectURL(url);
//             link.remove();
//
//             console.log('Download completed successfully');
//         } catch (error) {
//             console.error('Download error:', error);
//             setError(`Failed to download file: ${error.message}`);
//         }
//     };
//
//     return (
//         <div className="max-w-4xl mx-auto p-6 space-y-8">
//             {/* Header Section */}
//             <div className="flex justify-between items-center">
//                 <h2 className="text-3xl font-bold text-gray-800">New Project</h2>
//                 <div className="bg-blue-50 px-4 py-2 rounded-lg">
//                     <span className="text-blue-600 font-semibold">Tokens: {tokenCost}</span>
//                 </div>
//             </div>
//
//             {/* File Upload Section */}
//             <div
//                 onClick={() => !isFormDisabled && fileInputRef.current.click()}
//                 onDragOver={handleDragOver}
//                 onDragLeave={handleDragLeave}
//                 onDrop={handleDrop}
//                 className={`border-2 border-dashed rounded-xl p-8 text-center transition-colors duration-200
//                     ${isFormDisabled ? 'bg-gray-100 cursor-not-allowed' : 'cursor-pointer'}
//                     ${isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-blue-400'}`}
//             >
//                 <input
//                     type="file"
//                     ref={fileInputRef}
//                     onChange={handleFileChange}
//                     accept=".csv, .xlsx"
//                     className="hidden"
//                     disabled={isFormDisabled}
//                 />
//                 {file ? (
//                     <div className="flex items-center justify-center space-x-4">
//                         <div className="p-2 bg-blue-100 rounded-lg">
//                             <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//                                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
//                             </svg>
//                         </div>
//                         <div className="text-left">
//                             <p className="text-sm font-medium text-gray-900">{file.name}</p>
//                             <p className="text-sm text-gray-500">{(file.size / 1024).toFixed(2)} KB</p>
//                         </div>
//                         {!isFormDisabled && (
//                             <button
//                                 onClick={(e) => {
//                                     e.stopPropagation();
//                                     setFile(null);
//                                 }}
//                                 className="p-1 hover:bg-gray-100 rounded"
//                             >
//                                 <svg className="w-5 h-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//                                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
//                                 </svg>
//                             </button>
//                         )}
//                     </div>
//                 ) : (
//                     <div className="space-y-4">
//                         <svg className="mx-auto w-12 h-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//                             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
//                         </svg>
//                         <div>
//                             <p className="text-lg font-medium text-gray-900">Drop your file here or click to upload</p>
//                             <p className="text-sm text-gray-500">CSV only</p>
//                         </div>
//                     </div>
//                 )}
//             </div>
//
//             {/* Column Mapping Section */}
//             {columnNames.length > 0 && (
//                 <div className="bg-white rounded-xl shadow-sm p-6 space-y-6">
//                     <h3 className="text-xl font-semibold text-gray-800">Map Your Columns</h3>
//                     <div className="space-y-4">
//                         {Object.keys(selectedColumns).map((field) => (
//                             <div key={field} className="flex items-center gap-4">
//                                 <label className="w-1/3 text-sm font-medium text-gray-700 capitalize">
//                                     {field.replace(/([A-Z])/g, ' $1')}
//                                 </label>
//                                 <div className="w-2/3">
//                                     <select
//                                         value={selectedColumns[field]}
//                                         onChange={(e) => handleColumnSelect(field, e.target.value)}
//                                         onBlur={() => handleColumnBlur(field)}
//                                         disabled={isFormDisabled}
//                                         className={`w-full rounded-lg border ${
//                                             touchedFields[field] && selectedColumns[field] === ''
//                                                 ? 'border-red-300'
//                                                 : 'border-gray-300'
//                                         } px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent
//                             ${isFormDisabled ? 'bg-gray-100' : ''}`}
//                                     >
//                                         <option value="">Select Column</option>
//                                         {columnNames.map((name) => (
//                                             <option key={name} value={name}>{name}</option>
//                                         ))}
//                                     </select>
//                                     {touchedFields[field] && selectedColumns[field] === '' && (
//                                         <p className="mt-1 text-sm text-red-500">This field is required</p>
//                                     )}
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                 </div>
//             )}
//
//             {/* Upload Section with Time Estimation */}
//             {columnNames.length > 0 && (
//                 <div className="space-y-4">
//                     <div className="flex justify-between items-center">
//                         <div className="text-sm text-gray-600">
//                             Estimated processing time: {estimatedTime}
//                         </div>
//                         <button
//                             onClick={initiateUpload}
//                             disabled={isFormDisabled}
//                             className={`px-6 py-3 rounded-lg font-medium text-white
//                                 ${isFormDisabled ? 'bg-gray-400' : 'bg-blue-600 hover:bg-blue-700'}
//                                 transition-colors duration-200 flex items-center space-x-2`}
//                         >
//                             {loading ? (
//                                 <>
//                                     <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
//                                         <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
//                                         <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
//                                     </svg>
//                                     <span>Processing...</span>
//                                 </>
//                             ) : (
//                                 <>
//                                     <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//                                         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
//                                     </svg>
//                                     <span>Upload File</span>
//                                 </>
//                             )}
//                         </button>
//                     </div>
//                 </div>
//             )}
//
//             {/* Confirmation Dialog */}
//             {confirmationOpen && (
//                 <BatchConfirmationDialog
//                     onConfirm={handleUpload}
//                     onCancel={() => setConfirmationOpen(false)}
//                     tokenCost={tokenCost}
//                 />
//             )}
//
//             {/* Success Message */}
//             {downloadLink && (
//                 <div className="rounded-lg bg-green-50 p-4 mt-6">
//                     <div className="flex">
//                         <svg className="h-5 w-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//                             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
//                         </svg>
//                         <div className="ml-3">
//                             <p className="text-sm font-medium text-green-800">
//                                 File processed successfully.
//                                 <button
//                                     onClick={() => handleDownloadNewProject(downloadLink)}
//                                     className="ml-2 text-green-600 hover:text-green-500 underline"
//                                 >
//                                     Download here
//                                 </button>
//                             </p>
//                         </div>
//                     </div>
//                 </div>
//             )}
//
//             {batchStatus && (
//                 <div className="mt-6 p-4 bg-white rounded-lg shadow-sm">
//                     <div className="space-y-2">
//                         <div className="flex justify-between items-center">
//                             <span className="font-medium">Processing Status:</span>
//                             <span className="capitalize">{batchStatus}</span>
//                         </div>
//                         {processedRows > 0 && (
//                             <div className="space-y-1">
//                                 <div className="flex justify-between text-sm">
//                                     <span>Progress:</span>
//                                     <span>{processedRows} / {rowCount} rows</span>
//                                 </div>
//                                 <div className="w-full bg-gray-200 rounded-full h-2">
//                                     <div
//                                         className="bg-blue-600 h-2 rounded-full transition-all duration-500"
//                                         style={{ width: `${(processedRows / rowCount) * 100}%` }}
//                                     />
//                                 </div>
//                             </div>
//                         )}
//                     </div>
//                 </div>
//             )}
//
//             {/* Error Message */}
//             {error && (
//                 <div className="rounded-lg bg-red-50 p-4 mt-6">
//                     <div className="flex">
//                         <svg className="h-5 w-5 text-red-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
//                             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
//                         </svg>
//                         <div className="ml-3">
//                             <p className="text-sm font-medium text-red-800">{error}</p>
//                         </div>
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };
//
// export default NewProject;

import React, {useState, useRef, useMemo, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../axiosConfig';  // Update this path based on your file structure
import Papa from 'papaparse';

const NewProject = () => {
    const [file, setFile] = useState(null);
    const [columnNames, setColumnNames] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState({
        personalLinkedIn: '',
        companyLinkedIn: '',
        websiteURL: '',
        city: ''
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [downloadLink, setDownloadLink] = useState(null);
    const [tokenCost, setTokenCost] = useState(0);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [touchedFields, setTouchedFields] = useState({});
    const fileInputRef = useRef(null);
    const [rowCount, setRowCount] = useState(0);
    const navigate = useNavigate();
    const [batchJobId, setBatchJobId] = useState(null);
    const [batchStatus, setBatchStatus] = useState(null);
    const [processedRows, setProcessedRows] = useState(0);
    const [statusCheckInterval, setStatusCheckInterval] = useState(null);
    const [processingMethod, setProcessingMethod] = useState('immediate');

    const formatEstimatedTime = (seconds) => {
        if (seconds < 60) {
            return `${seconds} seconds`;
        } else if (seconds < 3600) {
            const minutes = Math.ceil(seconds / 60);
            return `${minutes} minute${minutes > 1 ? 's' : ''}`;
        } else {
            const hours = Math.ceil(seconds / 3600);
            return `${hours} hour${hours > 1 ? 's' : ''}`;
        }
    };

    const handleUploadError = (error) => {
        console.error('Upload error details:', {
            message: error.message,
            response: error.response?.data,
            file: file?.name,
            fileSize: file?.size
        });

        if (error.response?.status === 401) {
            localStorage.removeItem('token');
            navigate('/login');
        } else if (error.response?.status === 403 && error.response?.data.error.includes("Insufficient tokens")) {
            const confirm = window.confirm("You don't have enough tokens. Would you like to buy more credits?");
            if (confirm) {
                navigate("/credits");
            }
        } else {
            setError(error.response?.data?.error || 'Error uploading file. Please try again.');
        }
        setLoading(false);
    };

    const estimatedTime = useMemo(() => {
        const secondsPerRow = 15;
        const totalSeconds = rowCount * secondsPerRow;
        return formatEstimatedTime(totalSeconds);
    }, [rowCount]);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            // Add console logs to verify file content
            console.log('Selected file:', selectedFile);

            // Create a FileReader to verify file content
            const reader = new FileReader();
            reader.onload = (event) => {
                console.log('File content preview:', event.target.result.slice(0, 200));
            };
            reader.readAsText(selectedFile);

            handleFileSelection(selectedFile);
        }
    };
    // Calculate batch savings (25% discount, rounded up in favor of user)
    const calculateBatchSavings = (tokens) => {
        const savings = Math.ceil(tokens * 0.25);
        const discountedCost = tokens - savings;
        return {
            savings,
            discountedCost
        };
    };

    // Modify the existing confirmation dialog
    const BatchConfirmationDialog = ({ onConfirm, onCancel, tokenCost }) => {
        const showBatchOption = tokenCost > 10;
        const { savings, discountedCost } = calculateBatchSavings(tokenCost);

        return (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
                <div className="bg-white rounded-xl p-6 max-w-md w-full">
                    <h3 className="text-xl font-semibold mb-4">Choose Processing Method</h3>

                    {showBatchOption ? (
                        <div className="space-y-4 mb-6">
                            <div className="space-y-2">
                                <label className="flex items-start space-x-3">
                                    <input
                                        type="radio"
                                        name="processingMethod"
                                        value="immediate"
                                        checked={processingMethod === 'immediate'}
                                        onChange={(e) => setProcessingMethod(e.target.value)}
                                        className="mt-1"
                                    />
                                    <div>
                                        <p className="font-medium">Immediate Processing</p>
                                        <p className="text-sm text-gray-600">Process now for {tokenCost} tokens</p>
                                    </div>
                                </label>
                            </div>

                            <div className="space-y-2">
                                <label className="flex items-start space-x-3">
                                    <input
                                        type="radio"
                                        name="processingMethod"
                                        value="batch"
                                        checked={processingMethod === 'batch'}
                                        onChange={(e) => setProcessingMethod(e.target.value)}
                                        className="mt-1"
                                    />
                                    <div>
                                        <div className="font-medium flex items-center space-x-2">
                                            <span>Batch Processing</span>
                                            <span className="px-2 py-0.5 bg-green-100 text-green-800 text-xs rounded-full">
                                                Save {savings} tokens
                                            </span>
                                        </div>
                                        <p className="text-sm text-gray-600">
                                            Process within 24 hours for {discountedCost} tokens
                                        </p>
                                    </div>
                                </label>
                            </div>

                            <div className="bg-blue-50 p-3 rounded-lg text-sm text-blue-800">
                                💡 Batch processing can save you {savings} tokens by processing your request
                                during off-peak hours. Your results will be ready within 24 hours.
                            </div>
                        </div>
                    ) : (
                        <p className="text-gray-600 mb-6">
                            This job will deduct <span className="font-semibold text-blue-600">{tokenCost} tokens</span> from your account.
                        </p>
                    )}

                    <div className="flex space-x-4">
                        <button
                            onClick={() => onConfirm(processingMethod)}
                            className="flex-1 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200"
                        >
                            Confirm
                        </button>
                        <button
                            onClick={onCancel}
                            className="flex-1 px-4 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 transition-colors duration-200"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    const handleFileSelection = (selectedFile) => {
        if (selectedFile) {
            setFile(selectedFile);
            Papa.parse(selectedFile, {
                header: true,
                skipEmptyLines: true, // Skip empty lines
                complete: (results) => {
                    // Filter out empty rows
                    const validRows = results.data.filter(row => {
                        // Check if row has any non-empty values
                        return Object.values(row).some(value => value && value.trim() !== '');
                    });

                    setColumnNames(results.meta.fields);
                    setTokenCost(validRows.length);
                    setRowCount(validRows.length);
                    setTouchedFields({});

                    console.log(`Total rows: ${results.data.length}, Valid rows: ${validRows.length}`);
                },
                error: (error) => {
                    setError("Error parsing file: " + error.message);
                }
            });
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const droppedFile = e.dataTransfer.files[0];
        handleFileSelection(droppedFile);
    };

    const handleColumnSelect = (field, value) => {
        setSelectedColumns((prev) => ({ ...prev, [field]: value }));
        setTouchedFields((prev) => ({ ...prev, [field]: true }));
    };

    const handleColumnBlur = (field) => {
        setTouchedFields((prev) => ({ ...prev, [field]: true }));
    };

    const areAllColumnsSelected = Object.values(selectedColumns).every(col => col);

    // Update the initiateUpload function
    const initiateUpload = () => {
        const allTouched = Object.keys(selectedColumns).reduce((acc, field) => {
            acc[field] = true;
            return acc;
        }, {});
        setTouchedFields(allTouched);

        if (!areAllColumnsSelected) {
            setError("Please select all required columns.");
            return;
        }
        setError('');
        setConfirmationOpen(true);
    };

    // Modify the handleUpload function
    const handleUpload = async (method) => {
        setLoading(true);
        setConfirmationOpen(false);
        setError('');
        setDownloadLink(null);

        const formData = new FormData();
        formData.append('file', file);
        formData.append('processingMethod', method);

        Object.keys(selectedColumns).forEach((key) => {
            formData.append(key, selectedColumns[key]);
        });

        try {
            const response = await api.post('/upload_project', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            if (response.data.batch_job_id) {
                // Handle batch processing
                setBatchJobId(response.data.batch_job_id);

                // Start checking status
                const interval = setInterval(() => {
                    checkBatchStatus(response.data.batch_job_id);
                }, 10000); // Check every 10 seconds

                setStatusCheckInterval(interval);
            } else if (response.data.output_file_url) {
                // Handle immediate processing
                setDownloadLink(response.data.output_file_url);
                setLoading(false);
            }
        } catch (error) {
            handleUploadError(error);
        }
    };

    const checkBatchStatus = async (jobId) => {
        if (!jobId) {
            console.error('No job ID provided for status check');
            return;
        }

        try {
            const response = await api.get(`/batch_status/${jobId}`);
            console.log('Batch status response:', response.data);

            setBatchStatus(response.data.status);
            setProcessedRows(response.data.processed_rows || 0);

            if (response.data.status === 'completed') {
                setDownloadLink(`/download/${jobId}`);
                if (statusCheckInterval) {
                    clearInterval(statusCheckInterval);
                    setStatusCheckInterval(null);
                }
                setLoading(false);
            } else if (response.data.status === 'failed') {
                setError(response.data.error || 'Processing failed');
                if (statusCheckInterval) {
                    clearInterval(statusCheckInterval);
                    setStatusCheckInterval(null);
                }
                setLoading(false);
            }
        } catch (error) {
            console.error('Status check error:', error);
            setError('Error checking processing status');
            if (statusCheckInterval) {
                clearInterval(statusCheckInterval);
                setStatusCheckInterval(null);
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        return () => {
            if (statusCheckInterval) {
                clearInterval(statusCheckInterval);
            }
        };
    }, [statusCheckInterval]);

    const startStatusChecking = (jobId) => {
        if (!jobId) {
            console.error('No job ID provided');
            setError('Invalid batch job ID');
            return;
        }

        // Clear any existing interval
        if (statusCheckInterval) {
            clearInterval(statusCheckInterval);
        }

        const checkBatchStatus = async (jobId) => {
            try {
                const response = await api.get(`/batch_status/${jobId}`);
                setBatchStatus(response.data.status);
                setProcessedRows(response.data.processed_rows || 0);

                if (response.data.status === 'completed') {
                    // Update project status and download link
                    setDownloadLink(`/download/${jobId}`);
                    clearInterval(statusCheckInterval);
                    setLoading(false);
                } else if (response.data.status === 'failed') {
                    setError(response.data.error || 'Processing failed');
                    clearInterval(statusCheckInterval);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Status check error:', error);
                setError('Error checking processing status');
                clearInterval(statusCheckInterval);
                setLoading(false);
            }
        };

        // Start new interval to check status every 10 seconds
        const interval = setInterval(async () => {
            try {
                console.log('Checking status for job:', jobId); // Add this for debugging
                const response = await api.get(`/batch_status/${jobId}`);

                console.log('Status response:', response.data); // Add this for debugging

                setBatchStatus(response.data.status);
                setProcessedRows(response.data.processed_rows || 0);

                if (['completed', 'failed'].includes(response.data.status)) {
                    clearInterval(interval);
                    setLoading(false);
                    if (response.data.status === 'completed') {
                        setDownloadLink(response.data.output_file_url);
                    } else if (response.data.status === 'failed') {
                        setError(response.data.error || 'Processing failed');
                    }
                }
            } catch (error) {
                console.error('Status check error:', error);
                clearInterval(interval);
                setLoading(false);
                setError('Error checking processing status');
            }
        }, 60000); // Check every 10 seconds

        setStatusCheckInterval(interval);
    };

    useEffect(() => {
        return () => {
            if (statusCheckInterval) {
                clearInterval(statusCheckInterval);
            }
        };
    }, [statusCheckInterval]);

    // Check if the form should be disabled
    const isFormDisabled = loading || downloadLink !== null;

    const handleDownloadNewProject = async (downloadUrl) => {
        try {
            console.log('Attempting download with URL:', downloadUrl);
            const response = await api.get(downloadUrl, {
                responseType: 'blob',
                headers: {
                    'Accept': 'text/csv',
                }
            });

            if (!response.data || response.data.size === 0) {
                throw new Error('Received empty file');
            }

            // Create and trigger download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'processed_file.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);

            console.log('Download completed successfully');
        } catch (error) {
            console.error('Download error:', error);
            setError(`Failed to download file: ${error.message}`);
        }
    };

    return (
        <div className="max-w-4xl mx-auto p-6 space-y-8">
            {/* Header Section */}
            <div className="flex justify-between items-center">
                <h2 className="text-3xl font-bold text-gray-800">New Project</h2>
                <div className="bg-blue-50 px-4 py-2 rounded-lg">
                    <span className="text-blue-600 font-semibold">Tokens: {tokenCost}</span>
                </div>
            </div>

            {/* File Upload Section */}
            <div
                onClick={() => !isFormDisabled && fileInputRef.current.click()}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                className={`border-2 border-dashed rounded-xl p-8 text-center transition-colors duration-200 
                    ${isFormDisabled ? 'bg-gray-100 cursor-not-allowed' : 'cursor-pointer'}
                    ${isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-blue-400'}`}
            >
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    accept=".csv, .xlsx"
                    className="hidden"
                    disabled={isFormDisabled}
                />
                {file ? (
                    <div className="flex items-center justify-center space-x-4">
                        <div className="p-2 bg-blue-100 rounded-lg">
                            <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                            </svg>
                        </div>
                        <div className="text-left">
                            <p className="text-sm font-medium text-gray-900">{file.name}</p>
                            <p className="text-sm text-gray-500">{(file.size / 1024).toFixed(2)} KB</p>
                        </div>
                        {!isFormDisabled && (
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setFile(null);
                                }}
                                className="p-1 hover:bg-gray-100 rounded"
                            >
                                <svg className="w-5 h-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        )}
                    </div>
                ) : (
                    <div className="space-y-4">
                        <svg className="mx-auto w-12 h-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                        </svg>
                        <div>
                            <p className="text-lg font-medium text-gray-900">Drop your file here or click to upload</p>
                            <p className="text-sm text-gray-500">CSV only</p>
                        </div>
                    </div>
                )}
            </div>

            {/* Column Mapping Section */}
            {columnNames.length > 0 && (
                <div className="bg-white rounded-xl shadow-sm p-6 space-y-6">
                    <h3 className="text-xl font-semibold text-gray-800">Map Your Columns</h3>
                    <div className="space-y-4">
                        {Object.keys(selectedColumns).map((field) => (
                            <div key={field} className="flex items-center gap-4">
                                <label className="w-1/3 text-sm font-medium text-gray-700 capitalize">
                                    {field.replace(/([A-Z])/g, ' $1')}
                                </label>
                                <div className="w-2/3">
                                    <select
                                        value={selectedColumns[field]}
                                        onChange={(e) => handleColumnSelect(field, e.target.value)}
                                        onBlur={() => handleColumnBlur(field)}
                                        disabled={isFormDisabled}
                                        className={`w-full rounded-lg border ${
                                            touchedFields[field] && selectedColumns[field] === ''
                                                ? 'border-red-300'
                                                : 'border-gray-300'
                                        } px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent
                            ${isFormDisabled ? 'bg-gray-100' : ''}`}
                                    >
                                        <option value="">Select Column</option>
                                        {columnNames.map((name) => (
                                            <option key={name} value={name}>{name}</option>
                                        ))}
                                    </select>
                                    {touchedFields[field] && selectedColumns[field] === '' && (
                                        <p className="mt-1 text-sm text-red-500">This field is required</p>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {/* Upload Section with Time Estimation */}
            {columnNames.length > 0 && (
                <div className="space-y-4">
                    <div className="flex justify-between items-center">
                        <div className="text-sm text-gray-600">
                            Estimated processing time: {estimatedTime}
                        </div>
                        <button
                            onClick={initiateUpload}
                            disabled={isFormDisabled}
                            className={`px-6 py-3 rounded-lg font-medium text-white 
                                ${isFormDisabled ? 'bg-gray-400' : 'bg-blue-600 hover:bg-blue-700'} 
                                transition-colors duration-200 flex items-center space-x-2`}
                        >
                            {loading ? (
                                <>
                                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    <span>Processing...</span>
                                </>
                            ) : (
                                <>
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                                    </svg>
                                    <span>Upload File</span>
                                </>
                            )}
                        </button>
                    </div>
                </div>
            )}

            {/* Confirmation Dialog */}
            {confirmationOpen && (
                <BatchConfirmationDialog
                    onConfirm={handleUpload}
                    onCancel={() => setConfirmationOpen(false)}
                    tokenCost={tokenCost}
                />
            )}

            {/* Success Message */}
            {(downloadLink || batchStatus === 'completed') && (
                <div className="rounded-lg bg-green-50 p-4 mt-6">
                    <div className="flex">
                        <svg className="h-5 w-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                        <div className="ml-3">
                            <p className="text-sm font-medium text-green-800">
                                File processed successfully.
                                {downloadLink && (
                                    <button
                                        onClick={() => handleDownloadNewProject(downloadLink)}
                                        className="ml-2 text-green-600 hover:text-green-500 underline"
                                    >
                                        Download here
                                    </button>
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            )}

            {batchStatus && batchStatus !== 'completed' && (
                <div className="mt-6 p-4 bg-white rounded-lg shadow-sm">
                    <div className="space-y-2">
                        <div className="flex justify-between items-center">
                            <span className="font-medium">Processing Status:</span>
                            <span className="capitalize">{batchStatus}</span>
                        </div>
                        {processedRows > 0 && (
                            <div className="space-y-1">
                                <div className="flex justify-between text-sm">
                                    <span>Progress:</span>
                                    <span>{processedRows} / {rowCount} rows</span>
                                </div>
                                <div className="w-full bg-gray-200 rounded-full h-2">
                                    <div
                                        className="bg-blue-600 h-2 rounded-full transition-all duration-500"
                                        style={{ width: `${(processedRows / rowCount) * 100}%` }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {/* Error Message */}
            {error && (
                <div className="rounded-lg bg-red-50 p-4 mt-6">
                    <div className="flex">
                        <svg className="h-5 w-5 text-red-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <div className="ml-3">
                            <p className="text-sm font-medium text-red-800">{error}</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NewProject;