import React, { useState } from 'react';
import { Send, CheckCircle, AlertCircle } from 'lucide-react';
import emailjs from '@emailjs/browser';

const Support = () => {
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('idle'); // idle, sending, success, error

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!message.trim()) return;

        setStatus('sending');

        try {
            await emailjs.send(
                "service_88shw3b",  // You'll get this from EmailJS
                "template_qqov78k", // You'll get this from EmailJS
                {
                    message: message.trim(),
                    to_email: "info@aipeakflow.com"
                },
                "6jPnp7Vc5WRbegth_"   // You'll get this from EmailJS
            );

            setStatus('success');
            setMessage('');
            setTimeout(() => setStatus('idle'), 5000);
        } catch (error) {
            console.error('Error sending email:', error);
            setStatus('error');
            setTimeout(() => setStatus('idle'), 5000);
        }
    };

    return (
        <div className="max-w-2xl mx-auto p-6">
            <div className="bg-white rounded-xl shadow-sm p-6">
                <h2 className="text-2xl font-bold text-slate-800 mb-6">Support</h2>

                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <textarea
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="How can we help you?"
                            className="w-full h-40 p-4 border border-slate-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all duration-200"
                            disabled={status === 'sending'}
                        />
                    </div>

                    {status === 'success' && (
                        <div className="flex items-center gap-2 text-green-600 bg-green-50 p-3 rounded-lg">
                            <CheckCircle className="w-5 h-5" />
                            <span>Message sent successfully!</span>
                        </div>
                    )}

                    {status === 'error' && (
                        <div className="flex items-center gap-2 text-red-600 bg-red-50 p-3 rounded-lg">
                            <AlertCircle className="w-5 h-5" />
                            <span>Failed to send message. Please try again.</span>
                        </div>
                    )}

                    <button
                        type="submit"
                        disabled={!message.trim() || status === 'sending'}
                        className={`
                            w-full py-3 px-4 rounded-lg flex items-center justify-center gap-2
                            ${message.trim() && status !== 'sending'
                            ? 'bg-blue-600 hover:bg-blue-700 text-white'
                            : 'bg-slate-100 text-slate-400 cursor-not-allowed'
                        }
                            transition-all duration-200
                        `}
                    >
                        {status === 'sending' ? (
                            <>
                                <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                                Sending...
                            </>
                        ) : (
                            <>
                                <Send className="w-5 h-5" />
                                Send Message
                            </>
                        )}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Support;