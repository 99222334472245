// // Checkout.js
// import React, { useState } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
// import { ArrowLeft, CreditCard, Coins, Building2, User } from 'lucide-react';
// import api from '../axiosConfig';
//
// const Checkout = () => {
//     const location = useLocation();
//     const navigate = useNavigate();
//     const stripe = useStripe();
//     const elements = useElements();
//
//     const selectedPackage = location.state?.package;
//     const [isProcessing, setIsProcessing] = useState(false);
//     const [error, setError] = useState(null);
//     const [purchaseType, setPurchaseType] = useState('personal'); // 'personal' or 'company'
//     const [billingDetails, setBillingDetails] = useState({
//         // Personal info
//         firstName: '',
//         lastName: '',
//         email: '',
//         // Address (will be either personal or company based on type)
//         address: '',
//         city: '',
//         country: '',
//         postal_code: '',
//         // Company info (optional)
//         companyName: '',
//         vatNumber: ''
//     });
//
//     const countries = [
//         // North America
//         { code: 'US', name: 'United States' },
//         { code: 'CA', name: 'Canada' },
//
//         // Europe
//         { code: 'GB', name: 'United Kingdom' },
//         { code: 'DE', name: 'Germany' },
//         { code: 'FR', name: 'France' },
//         { code: 'IT', name: 'Italy' },
//         { code: 'ES', name: 'Spain' },
//         { code: 'NL', name: 'Netherlands' },
//         { code: 'BE', name: 'Belgium' },
//         { code: 'SE', name: 'Sweden' },
//         { code: 'NO', name: 'Norway' },
//         { code: 'DK', name: 'Denmark' },
//         { code: 'FI', name: 'Finland' },
//         { code: 'IE', name: 'Ireland' },
//         { code: 'AT', name: 'Austria' },
//         { code: 'CH', name: 'Switzerland' },
//         { code: 'PL', name: 'Poland' },
//         { code: 'PT', name: 'Portugal' },
//         { code: 'GR', name: 'Greece' },
//         { code: 'HU', name: 'Hungary' },
//         { code: 'CZ', name: 'Czech Republic' },
//         { code: 'HR', name: 'Croatia' },
//         { code: 'RO', name: 'Romania' },
//         { code: 'BG', name: 'Bulgaria' },
//
//         // Asia Pacific
//         { code: 'JP', name: 'Japan' },
//         { code: 'KR', name: 'South Korea' },
//         { code: 'CN', name: 'China' },
//         { code: 'HK', name: 'Hong Kong' },
//         { code: 'SG', name: 'Singapore' },
//         { code: 'AU', name: 'Australia' },
//         { code: 'NZ', name: 'New Zealand' },
//         { code: 'IN', name: 'India' },
//
//         // Middle East
//         { code: 'IL', name: 'Israel' },
//         { code: 'AE', name: 'United Arab Emirates' },
//         { code: 'SA', name: 'Saudi Arabia' },
//
//         // Latin America
//         { code: 'MX', name: 'Mexico' },
//         { code: 'BR', name: 'Brazil' },
//         { code: 'AR', name: 'Argentina' }
//     ].sort((a, b) => a.name.localeCompare(b.name)); // Alphabetically sorted
//
//     // Redirect if no package selected
//     if (!selectedPackage) {
//         navigate('/credits');
//         return null;
//     }
//
//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         if (!stripe || !elements) return;
//
//         setIsProcessing(true);
//         setError(null);
//
//         try {
//             // Create payment intent with all necessary metadata
//             const { data } = await api.post('/create-payment-intent', {
//                 amount: selectedPackage.price,
//                 tokens: selectedPackage.tokens,
//                 billing_details: billingDetails,
//                 type: purchaseType  // Add this to identify if it's company or personal
//             });
//
//             const cardElement = elements.getElement(CardElement);
//
//             // Create billing details object correctly for Stripe
//             const paymentData = {
//                 payment_method: {
//                     card: cardElement,
//                     billing_details: {
//                         name: `${billingDetails.firstName} ${billingDetails.lastName}`,
//                         email: billingDetails.email,
//                         address: {
//                             line1: billingDetails.address,
//                             city: billingDetails.city,
//                             country: billingDetails.country,
//                             postal_code: billingDetails.postal_code
//                         }
//                     }
//                 }
//             };
//
//             // Note: Don't add company details here - they should be handled in the backend
//             // when creating the payment intent
//
//             const result = await stripe.confirmCardPayment(data.clientSecret, paymentData);
//
//             if (result.error) {
//                 setError(result.error.message);
//             } else {
//                 navigate('/payment-success');
//             }
//         } catch (error) {
//             setError("An error occurred. Please try again.");
//             console.error('Error:', error);
//         } finally {
//             setIsProcessing(false);
//         }
//     };
//
//     return (
//         <div className="max-w-2xl mx-auto p-6">
//             <div className="bg-white rounded-xl shadow-sm">
//                 {/* Header with back button */}
//                 <div className="p-4 border-b border-slate-100">
//                     <button
//                         onClick={() => navigate('/credits')}
//                         className="text-slate-600 hover:text-slate-800 flex items-center"
//                     >
//                         <ArrowLeft className="w-4 h-4 mr-2" />
//                         Back to Packages
//                     </button>
//                 </div>
//
//                 <div className="p-6">
//                     {/* Order Summary */}
//                     <div className="bg-blue-50 p-4 rounded-lg mb-6">
//                         <div className="flex items-center justify-between mb-2">
//                             <span className="text-slate-600">You're purchasing</span>
//                             <div className="flex items-center text-blue-600">
//                                 <Coins className="w-5 h-5 mr-2" />
//                                 <span className="text-xl font-bold">
//                                     {selectedPackage.tokens.toLocaleString()} tokens
//                                 </span>
//                             </div>
//                         </div>
//                         <div className="flex items-center justify-between text-sm text-slate-500">
//                             <span>Price per token:</span>
//                             <span>${selectedPackage.pricePerToken.toFixed(4)}</span>
//                         </div>
//                         <div className="flex items-center justify-between mt-2 text-lg font-bold">
//                             <span>Total:</span>
//                             <span>${selectedPackage.price.toFixed(2)}</span>
//                         </div>
//                     </div>
//
//                     {/* Purchase Type Toggle */}
//                     <div className="flex justify-center space-x-4 mb-6">
//                         <button
//                             type="button"
//                             onClick={() => setPurchaseType('personal')}
//                             className={`flex items-center px-4 py-2 rounded-lg ${
//                                 purchaseType === 'personal'
//                                     ? 'bg-blue-100 text-blue-700'
//                                     : 'bg-slate-100 text-slate-600 hover:bg-slate-200'
//                             }`}
//                         >
//                             <User className="w-4 h-4 mr-2" />
//                             Personal Purchase
//                         </button>
//                         <button
//                             type="button"
//                             onClick={() => setPurchaseType('company')}
//                             className={`flex items-center px-4 py-2 rounded-lg ${
//                                 purchaseType === 'company'
//                                     ? 'bg-blue-100 text-blue-700'
//                                     : 'bg-slate-100 text-slate-600 hover:bg-slate-200'
//                             }`}
//                         >
//                             <Building2 className="w-4 h-4 mr-2" />
//                             Company Purchase
//                         </button>
//                     </div>
//
//                     {/* Payment Form */}
//                     <form onSubmit={handleSubmit} className="space-y-4">
//                         {/* Personal Information */}
//                         <div className="grid grid-cols-2 gap-4">
//                             <div>
//                                 <label className="block text-sm font-medium text-slate-700 mb-1">
//                                     First Name *
//                                 </label>
//                                 <input
//                                     required
//                                     type="text"
//                                     className="w-full px-3 py-2 border border-slate-300 rounded-md"
//                                     value={billingDetails.firstName}
//                                     onChange={(e) => setBillingDetails({
//                                         ...billingDetails,
//                                         firstName: e.target.value
//                                     })}
//                                 />
//                             </div>
//                             <div>
//                                 <label className="block text-sm font-medium text-slate-700 mb-1">
//                                     Last Name *
//                                 </label>
//                                 <input
//                                     required
//                                     type="text"
//                                     className="w-full px-3 py-2 border border-slate-300 rounded-md"
//                                     value={billingDetails.lastName}
//                                     onChange={(e) => setBillingDetails({
//                                         ...billingDetails,
//                                         lastName: e.target.value
//                                     })}
//                                 />
//                             </div>
//                         </div>
//
//                         <div>
//                             <label className="block text-sm font-medium text-slate-700 mb-1">
//                                 Email *
//                             </label>
//                             <input
//                                 required
//                                 type="email"
//                                 className="w-full px-3 py-2 border border-slate-300 rounded-md"
//                                 value={billingDetails.email}
//                                 onChange={(e) => setBillingDetails({
//                                     ...billingDetails,
//                                     email: e.target.value
//                                 })}
//                             />
//                         </div>
//
//                         {/* Company Details (if company purchase) */}
//                         {purchaseType === 'company' && (
//                             <div className="space-y-4 p-4 bg-blue-50 rounded-lg">
//                                 <div>
//                                     <label className="block text-sm font-medium text-slate-700 mb-1">
//                                         Company Name *
//                                     </label>
//                                     <input
//                                         required
//                                         type="text"
//                                         className="w-full px-3 py-2 border border-slate-300 rounded-md"
//                                         value={billingDetails.companyName}
//                                         onChange={(e) => setBillingDetails({
//                                             ...billingDetails,
//                                             companyName: e.target.value
//                                         })}
//                                     />
//                                 </div>
//                                 <div>
//                                     <label className="block text-sm font-medium text-slate-700 mb-1">
//                                         VAT Number *
//                                     </label>
//                                     <input
//                                         required
//                                         type="text"
//                                         className="w-full px-3 py-2 border border-slate-300 rounded-md"
//                                         value={billingDetails.vatNumber}
//                                         onChange={(e) => setBillingDetails({
//                                             ...billingDetails,
//                                             vatNumber: e.target.value
//                                         })}
//                                     />
//                                 </div>
//                             </div>
//                         )}
//
//                         {/* Address Fields */}
//                         <div>
//                             <label className="block text-sm font-medium text-slate-700 mb-1">
//                                 {purchaseType === 'company' ? 'Company Address *' : 'Address *'}
//                             </label>
//                             <input
//                                 required
//                                 type="text"
//                                 className="w-full px-3 py-2 border border-slate-300 rounded-md"
//                                 value={billingDetails.address}
//                                 onChange={(e) => setBillingDetails({
//                                     ...billingDetails,
//                                     address: e.target.value
//                                 })}
//                             />
//                         </div>
//
//                         <div className="grid grid-cols-2 gap-4">
//                             <div>
//                                 <label className="text-sm font-medium text-slate-700">Country *</label>
//                                 <select
//                                     required
//                                     className="mt-1 w-full px-3 py-2 border border-slate-300 rounded-md bg-white"
//                                     value={billingDetails.country}
//                                     onChange={(e) => setBillingDetails({
//                                         ...billingDetails,
//                                         country: e.target.value
//                                     })}
//                                 >
//                                     <option value="">Select a country</option>
//                                     {countries.map((country) => (
//                                         <option key={country.code} value={country.code}>
//                                             {country.name}
//                                         </option>
//                                     ))}
//                                 </select>
//                             </div>
//                             <div>
//                                 <label className="block text-sm font-medium text-slate-700 mb-1">
//                                     City *
//                                 </label>
//                                 <input
//                                     required
//                                     type="text"
//                                     className="w-full px-3 py-2 border border-slate-300 rounded-md"
//                                     value={billingDetails.city}
//                                     onChange={(e) => setBillingDetails({
//                                         ...billingDetails,
//                                         city: e.target.value
//                                     })}
//                                 />
//                             </div>
//                         </div>
//
//                         <div>
//                             <label className="block text-sm font-medium text-slate-700 mb-1">
//                                 Postal Code *
//                             </label>
//                             <input
//                                 required
//                                 type="text"
//                                 className="w-full px-3 py-2 border border-slate-300 rounded-md"
//                                 value={billingDetails.postal_code}
//                                 onChange={(e) => setBillingDetails({
//                                     ...billingDetails,
//                                     postal_code: e.target.value
//                                 })}
//                             />
//                         </div>
//
//                         {/* Card Details */}
//                         <div>
//                             <label className="block text-sm font-medium text-slate-700 mb-1">
//                                 Card Details *
//                             </label>
//                             <div className="border border-slate-300 rounded-md p-3">
//                                 <CardElement options={{
//                                     style: {
//                                         base: {
//                                             fontSize: '16px',
//                                             color: '#32325d',
//                                             '::placeholder': {
//                                                 color: '#a0aec0',
//                                             },
//                                         },
//                                         invalid: {
//                                             color: '#fa755a',
//                                         },
//                                     },
//                                     hidePostalCode: true  // Add this line to hide Stripe's postal code field
//                                 }} />
//                             </div>
//                         </div>
//
//                         {error && (
//                             <div className="text-red-500 text-sm">{error}</div>
//                         )}
//
//                         <button
//                             type="submit"
//                             disabled={!stripe || isProcessing}
//                             className="w-full bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 flex items-center justify-center gap-2 disabled:opacity-70 disabled:cursor-not-allowed"
//                         >
//                             {isProcessing ? (
//                                 <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />
//                             ) : (
//                                 <>
//                                     <CreditCard className="w-5 h-5" />
//                                     Pay ${selectedPackage.price.toFixed(2)}
//                                 </>
//                             )}
//                         </button>
//                     </form>
//                 </div>
//             </div>
//         </div>
//     );
// };
//
// export default Checkout;

import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { ArrowLeft, CreditCard, Coins, Building2, User, Shield } from 'lucide-react';
import api from '../axiosConfig';

const Checkout = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();

    const selectedPackage = location.state?.package;
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState(null);
    const [purchaseType, setPurchaseType] = useState('personal');
    const [billingDetails, setBillingDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        address: '',
        city: '',
        country: '',
        postal_code: '',
        companyName: '',
        vatNumber: ''
    });

    const countries = [
        // North America
        { code: 'US', name: 'United States' },
        { code: 'CA', name: 'Canada' },
        // Europe
        { code: 'GB', name: 'United Kingdom' },
        { code: 'DE', name: 'Germany' },
        { code: 'FR', name: 'France' },
        { code: 'IT', name: 'Italy' },
        { code: 'ES', name: 'Spain' },
        { code: 'NL', name: 'Netherlands' },
        { code: 'BE', name: 'Belgium' },
        { code: 'SE', name: 'Sweden' },
        { code: 'NO', name: 'Norway' },
        { code: 'DK', name: 'Denmark' },
        { code: 'FI', name: 'Finland' },
        { code: 'IE', name: 'Ireland' },
        { code: 'AT', name: 'Austria' },
        { code: 'CH', name: 'Switzerland' },
        { code: 'PL', name: 'Poland' },
        { code: 'PT', name: 'Portugal' },
        { code: 'GR', name: 'Greece' },
        { code: 'HU', name: 'Hungary' },
        { code: 'CZ', name: 'Czech Republic' },
        { code: 'HR', name: 'Croatia' },
        { code: 'RO', name: 'Romania' },
        { code: 'BG', name: 'Bulgaria' },
        // Asia Pacific
        { code: 'JP', name: 'Japan' },
        { code: 'KR', name: 'South Korea' },
        { code: 'CN', name: 'China' },
        { code: 'HK', name: 'Hong Kong' },
        { code: 'SG', name: 'Singapore' },
        { code: 'AU', name: 'Australia' },
        { code: 'NZ', name: 'New Zealand' },
        { code: 'IN', name: 'India' },
        // Middle East
        { code: 'IL', name: 'Israel' },
        { code: 'AE', name: 'United Arab Emirates' },
        { code: 'SA', name: 'Saudi Arabia' },
        // Latin America
        { code: 'MX', name: 'Mexico' },
        { code: 'BR', name: 'Brazil' },
        { code: 'AR', name: 'Argentina' }
    ].sort((a, b) => a.name.localeCompare(b.name));

    if (!selectedPackage) {
        navigate('/credits');
        return null;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) return;

        setIsProcessing(true);
        setError(null);

        try {
            // Use priceWithFees instead of base price
            const { data } = await api.post('/create-payment-intent', {
                amount: selectedPackage.priceWithFees, // Use price with fees
                tokens: selectedPackage.tokens,
                billing_details: billingDetails,
                type: purchaseType
            });

            const cardElement = elements.getElement(CardElement);

            const paymentData = {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: `${billingDetails.firstName} ${billingDetails.lastName}`,
                        email: billingDetails.email,
                        address: {
                            line1: billingDetails.address,
                            city: billingDetails.city,
                            country: billingDetails.country,
                            postal_code: billingDetails.postal_code
                        }
                    }
                }
            };

            const result = await stripe.confirmCardPayment(data.clientSecret, paymentData);

            if (result.error) {
                setError(result.error.message);
            } else {
                navigate('/payment-success');
            }
        } catch (error) {
            setError("An error occurred. Please try again.");
            console.error('Error:', error);
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <div className="max-w-2xl mx-auto p-6">
            <div className="bg-white rounded-xl shadow-sm">
                {/* Header with back button */}
                <div className="p-4 border-b border-slate-100">
                    <button
                        onClick={() => navigate('/credits')}
                        className="text-slate-600 hover:text-slate-800 flex items-center"
                    >
                        <ArrowLeft className="w-4 h-4 mr-2" />
                        Back to Packages
                    </button>
                </div>

                <div className="p-6">
                    {/* Order Summary */}
                    <div className="bg-blue-50 p-4 rounded-lg mb-6">
                        <div className="flex items-center justify-between mb-2">
                            <span className="text-slate-600">You're purchasing</span>
                            <div className="flex items-center text-blue-600">
                                <Coins className="w-5 h-5 mr-2" />
                                <span className="text-xl font-bold">
                                    {selectedPackage.tokens.toLocaleString()} tokens
                                </span>
                            </div>
                        </div>
                        <div className="flex items-center justify-between text-sm text-slate-500">
                            <span>Price per token:</span>
                            <span>${selectedPackage.pricePerToken.toFixed(4)}</span>
                        </div>
                        <div className="flex items-center justify-between text-sm text-slate-500">
                            <span>Base price:</span>
                            <span>${selectedPackage.price.toFixed(2)}</span>
                        </div>
                        <div className="flex items-center justify-between mt-2 text-lg font-bold">
                            <span>Total (including fees):</span>
                            <span>${selectedPackage.priceWithFees.toFixed(2)}</span>
                        </div>
                    </div>

                    {/* Purchase Type Toggle */}
                    <div className="flex justify-center space-x-4 mb-6">
                        <button
                            type="button"
                            onClick={() => setPurchaseType('personal')}
                            className={`flex items-center px-4 py-2 rounded-lg ${
                                purchaseType === 'personal'
                                    ? 'bg-blue-100 text-blue-700'
                                    : 'bg-slate-100 text-slate-600 hover:bg-slate-200'
                            }`}
                        >
                            <User className="w-4 h-4 mr-2" />
                            Personal Purchase
                        </button>
                        <button
                            type="button"
                            onClick={() => setPurchaseType('company')}
                            className={`flex items-center px-4 py-2 rounded-lg ${
                                purchaseType === 'company'
                                    ? 'bg-blue-100 text-blue-700'
                                    : 'bg-slate-100 text-slate-600 hover:bg-slate-200'
                            }`}
                        >
                            <Building2 className="w-4 h-4 mr-2" />
                            Company Purchase
                        </button>
                    </div>

                    {/* Payment Form */}
                    <form onSubmit={handleSubmit} className="space-y-4">
                        {/* Form fields remain the same... */}
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium text-slate-700 mb-1">
                                    First Name *
                                </label>
                                <input
                                    required
                                    type="text"
                                    className="w-full px-3 py-2 border border-slate-300 rounded-md"
                                    value={billingDetails.firstName}
                                    onChange={(e) => setBillingDetails({
                                        ...billingDetails,
                                        firstName: e.target.value
                                    })}
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-slate-700 mb-1">
                                    Last Name *
                                </label>
                                <input
                                    required
                                    type="text"
                                    className="w-full px-3 py-2 border border-slate-300 rounded-md"
                                    value={billingDetails.lastName}
                                    onChange={(e) => setBillingDetails({
                                        ...billingDetails,
                                        lastName: e.target.value
                                    })}
                                />
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-slate-700 mb-1">
                                Email *
                            </label>
                            <input
                                required
                                type="email"
                                className="w-full px-3 py-2 border border-slate-300 rounded-md"
                                value={billingDetails.email}
                                onChange={(e) => setBillingDetails({
                                    ...billingDetails,
                                    email: e.target.value
                                })}
                            />
                        </div>

                        {/* Company Details */}
                        {purchaseType === 'company' && (
                            <div className="space-y-4 p-4 bg-blue-50 rounded-lg">
                                <div>
                                    <label className="block text-sm font-medium text-slate-700 mb-1">
                                        Company Name *
                                    </label>
                                    <input
                                        required
                                        type="text"
                                        className="w-full px-3 py-2 border border-slate-300 rounded-md"
                                        value={billingDetails.companyName}
                                        onChange={(e) => setBillingDetails({
                                            ...billingDetails,
                                            companyName: e.target.value
                                        })}
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-slate-700 mb-1">
                                        VAT Number *
                                    </label>
                                    <input
                                        required
                                        type="text"
                                        className="w-full px-3 py-2 border border-slate-300 rounded-md"
                                        value={billingDetails.vatNumber}
                                        onChange={(e) => setBillingDetails({
                                            ...billingDetails,
                                            vatNumber: e.target.value
                                        })}
                                    />
                                </div>
                            </div>
                        )}

                        {/* Address Fields */}
                        <div>
                            <label className="block text-sm font-medium text-slate-700 mb-1">
                                {purchaseType === 'company' ? 'Company Address *' : 'Address *'}
                            </label>
                            <input
                                required
                                type="text"
                                className="w-full px-3 py-2 border border-slate-300 rounded-md"
                                value={billingDetails.address}
                                onChange={(e) => setBillingDetails({
                                    ...billingDetails,
                                    address: e.target.value
                                })}
                            />
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <label className="text-sm font-medium text-slate-700">Country *</label>
                                <select
                                    required
                                    className="mt-1 w-full px-3 py-2 border border-slate-300 rounded-md bg-white"
                                    value={billingDetails.country}
                                    onChange={(e) => setBillingDetails({
                                        ...billingDetails,
                                        country: e.target.value
                                    })}
                                >
                                    <option value="">Select a country</option>
                                    {countries.map((country) => (
                                        <option key={country.code} value={country.code}>
                                            {country.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-slate-700 mb-1">
                                    City *
                                </label>
                                <input
                                    required
                                    type="text"
                                    className="w-full px-3 py-2 border border-slate-300 rounded-md"
                                    value={billingDetails.city}
                                    onChange={(e) => setBillingDetails({
                                        ...billingDetails,
                                        city: e.target.value
                                    })}
                                />
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-slate-700 mb-1">
                                Postal Code *
                            </label>
                            <input
                                required
                                type="text"
                                className="w-full px-3 py-2 border border-slate-300 rounded-md"
                                value={billingDetails.postal_code}
                                onChange={(e) => setBillingDetails({
                                    ...billingDetails,
                                    postal_code: e.target.value
                                })}
                            />
                        </div>

                        {/* Card Details */}
                        <div>
                            <label className="block text-sm font-medium text-slate-700 mb-1">
                                Card Details *
                            </label>
                            <div className="border border-slate-300 rounded-md p-3">
                                <CardElement options={{
                                    style: {
                                        base: {
                                            fontSize: '16px',
                                            color: '#32325d',
                                            '::placeholder': {
                                                color: '#a0aec0',
                                            },
                                        },
                                        invalid: {
                                            color: '#fa755a',
                                        },
                                    },
                                    hidePostalCode: true
                                }} />
                            </div>
                        </div>

                        {error && (
                            <div className="text-red-500 text-sm">{error}</div>
                        )}

                        <button
                            type="submit"
                            disabled={!stripe || isProcessing}
                            className="w-full bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 flex items-center justify-center gap-2 disabled:opacity-70 disabled:cursor-not-allowed"
                        >
                            {isProcessing ? (
                                <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />
                            ) : (
                                <>
                                    <CreditCard className="w-5 h-5" />
                                    Pay ${selectedPackage.priceWithFees.toFixed(2)}
                                </>
                            )}
                        </button>

                        {/* Payment Information */}
                        <div className="mt-4 space-y-2">
                            <div className="flex items-center gap-2 justify-center text-sm text-slate-500">
                                <Shield className="w-4 h-4" />
                                <span>Secure payment processing by Stripe</span>
                            </div>
                            <div className="text-xs text-center text-slate-500">
                                Price includes processing fees
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Checkout;