// // src/App.js
// import React, { useState } from 'react';
// import Sidebar from './components/Sidebar';
// import NewProject from './components/NewProject';
// import Projects from './components/Projects';
// import Account from './components/Account';
// import Credits from './components/Credits';
// import TokenDisplay from './components/Header';
// import Login from './components/Login';
// import Signup from './components/Signup';
//
// function App() {
//   const [view, setView] = useState("New Project");
//   const [token, setToken] = useState(localStorage.getItem('accessToken') || null);
//   const [isSignup, setIsSignup] = useState(false);
//
//   const renderView = () => {
//     switch (view) {
//       case "New Project":
//         return <NewProject />;
//       case "Projects":
//         return <Projects />;
//       case "Credits":
//         return <Credits />;  // No need for pricingOptions prop here
//       case "Account":
//         return <Account setView={setView} />;
//       default:
//         return <NewProject />;
//     }
//   };
//
//   if (!token) {
//     return isSignup ? (
//         <Signup setToken={setToken} setIsSignup={setIsSignup} />
//     ) : (
//         <Login setToken={setToken} setIsSignup={setIsSignup} />
//     );
//   }
//
//   return (
//       <div className="flex flex-col">
//         <TokenDisplay />
//         <div className="flex">
//           <Sidebar setView={setView} view={view} />
//           <div className="flex-1 pt-4">
//             {renderView()}
//           </div>
//         </div>
//       </div>
//   );
// }
//
// export default App;

// src/App.js
// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import Sidebar from './components/Sidebar';
// import NewProject from './components/NewProject';
// import Projects from './components/Projects';
// import Account from './components/Account';
// import Credits from './components/Credits';
// import TokenDisplay from './components/Header';
// import Login from './components/Login';
// import Signup from './components/Signup';
// import LandingPage from './components/LandingPage';
//
// function App() {
//     const [token, setToken] = useState(localStorage.getItem('accessToken'));
//
//     useEffect(() => {
//         // Listen for token changes to handle logout
//         const handleStorageChange = () => setToken(localStorage.getItem('accessToken'));
//         window.addEventListener('storage', handleStorageChange);
//         return () => window.removeEventListener('storage', handleStorageChange);
//     }, []);
//
//     const handleLogout = () => {
//         localStorage.removeItem('accessToken');
//         setToken(null);
//     };
//
//     return (
//         <Router>
//             {token ? (
//                 <div className="flex">
//                     <Sidebar handleLogout={handleLogout} />
//                     <div className="flex-1">
//                         <TokenDisplay />
//                         <Routes>
//                             <Route path="/" element={<Navigate to="/new-project" />} />
//                             <Route path="/new-project" element={<NewProject />} />
//                             <Route path="/projects" element={<Projects />} />
//                             <Route path="/credits" element={<Credits />} />
//                             <Route path="/account" element={<Account />} />
//                             <Route path="*" element={<Navigate to="/new-project" />} />
//                         </Routes>
//                     </div>
//                 </div>
//             ) : (
//                 <Routes>
//                     <Route path="/" element={<LandingPage />} />
//                     <Route path="/login" element={<Login setToken={setToken} />} />
//                     <Route path="/signup" element={<Signup setToken={setToken} />} />
//                     <Route path="*" element={<Navigate to="/login" />} />
//                 </Routes>
//             )}
//         </Router>
//     );
// }
//
// export default App;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import api from './axiosConfig';  // Import the configured axios instance
import Sidebar from './components/Sidebar';
import NewProject from './components/NewProject';
import Projects from './components/Projects';
import Account from './components/Account';
import Credits from './components/Credits';
import TokenDisplay from './components/Header';
import Login from './components/Login';
import Signup from './components/Signup';
import LandingPage from './components/LandingPage';
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import Checkout from "./components/Checkout";
import Support from "./components/Support";
import Invoices from "./components/Invoices";

function App() {
    // Change 'accessToken' to 'token' to be consistent with our naming
    const [token, setToken] = useState(localStorage.getItem('token'));
    //const stripePromise = loadStripe('pk_live_51QG282I8Y1KiSEf5pXd1mzVejXp44DJhSzpZ0d4mQ2BbXUQwtKCi5EvCrJJaqLumiDUEFLPr5FM77TWbvwyfrzAY00D6C5CnkH'); //LIVE
   //const stripePromise = loadStripe('pk_test_51QG282I8Y1KiSEf5PGJnQ9q6kU8iC81DPh8NceeVRcJYtfhe4XsIqT0Yboyag399w9Tn9uTdnH5LvbIn2nFeQEgh00mob7r7if'); //TEST

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY_LIVE);

    useEffect(() => {
        // Verify token on app load
        const verifyToken = async () => {
            if (token) {
                try {
                    await api.get('/dashboard');
                } catch (error) {
                    console.error('Token validation error:', error);
                    // If token is invalid, clear it
                    handleLogout();
                }
            }
        };

        verifyToken();

        // Listen for token changes to handle logout across tabs
        const handleStorageChange = (e) => {
            if (e.key === 'token') {
                setToken(e.newValue);
                if (!e.newValue) {
                    // Token was removed in another tab
                    window.location.href = '/login';
                }
            }
        };

        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, [token]);

    const handleLogout = () => {
        localStorage.removeItem('token');
        setToken(null);
    };

    // Protected Route wrapper component
    const ProtectedRoute = ({ children }) => {
        if (!token) {
            return <Navigate to="/login" replace />;
        }
        return children;
    };

    return (
        <Router>
            {token ? (
                <div className="flex h-screen overflow-hidden">
                    <div className="w-64 bg-slate-800">
                        <Sidebar handleLogout={handleLogout} />
                    </div>
                    <div className="flex-1 flex flex-col overflow-hidden">
                        <TokenDisplay />
                        <div className="h-[calc(100vh-64px)] overflow-auto"> {/* Adjust 64px based on your TokenDisplay height */}
                            <Routes>
                                <Route path="/" element={<Navigate to="/new-project" />} />
                                <Route
                                    path="/new-project"
                                    element={
                                        <ProtectedRoute>
                                            <NewProject />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/projects"
                                    element={
                                        <ProtectedRoute>
                                            <Projects />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/credits"
                                    element={
                                        <ProtectedRoute>
                                            <Credits />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/checkout"
                                    element={
                                        <ProtectedRoute>
                                            <Elements stripe={stripePromise}>
                                                <Checkout />
                                            </Elements>
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/account"
                                    element={
                                        <ProtectedRoute>
                                            <Account />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/support"
                                    element={
                                        <ProtectedRoute>
                                            <Support />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/invoices"
                                    element={
                                        <ProtectedRoute>
                                            <Invoices />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route path="*" element={<Navigate to="/new-project" />} />
                            </Routes>
                        </div>
                    </div>
                </div>
            ) : (
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route
                        path="/login"
                        element={
                            <Login
                                setToken={(newToken) => {
                                    setToken(newToken);
                                    localStorage.setItem('token', newToken);
                                }}
                            />
                        }
                    />
                    <Route
                        path="/signup"
                        element={
                            <Signup
                                setToken={(newToken) => {
                                    setToken(newToken);
                                    localStorage.setItem('token', newToken);
                                }}
                            />
                        }
                    />
                    <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
            )}
        </Router>
    );
}

export default App;