import React, { useEffect, useRef, useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {
    ArrowRight, Mail, BarChart2, Upload,
    Download, Sparkles, Shield, Clock,
    CheckCircle2, Zap, Users, Globe, Coins
} from 'lucide-react';

const style = document.createElement('style');
style.textContent = `
@keyframes blob {
    0%, 100% { transform: translate(0, 0) scale(1); }
    25% { transform: translate(20px, -20px) scale(1.1); }
    50% { transform: translate(-20px, 20px) scale(0.9); }
    75% { transform: translate(20px, 20px) scale(1.05); }
}

@keyframes fade-in-up {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.animate-blob {
    animation: blob 25s infinite cubic-bezier(.4, 0, .2, 1);
}

.animate-fade-in-up {
    animation: fade-in-up 0.6s ease-out forwards;
}
`;
document.head.appendChild(style);

const LandingPage = () => {
    const navigate = useNavigate(); // Add navigation hook
    const [isVisible, setIsVisible] = useState({});
    const observerRefs = useRef([]);

    const tokenPackages = [
        { tokens: 2000, pricePerToken: 0.005, price: 10.00, savings: "0%" },
        { tokens: 5000, pricePerToken: 0.004, price: 20.00, savings: "20%" },
        { tokens: 10000, pricePerToken: 0.0035, price: 35.00, savings: "30%" },
        { tokens: 25000, pricePerToken: 0.003, price: 75.00, savings: "40%" },
        { tokens: 50000, pricePerToken: 0.0028, price: 140.00, savings: "44%" },
        { tokens: 100000, pricePerToken: 0.0025, price: 250.00, savings: "50%" },
        { tokens: 250000, pricePerToken: 0.0023, price: 575.00, savings: "54%" },
        { tokens: 500000, pricePerToken: 0.0021, price: 1050.00, savings: "58%" },
        { tokens: 1000000, pricePerToken: 0.002, price: 2000.00, savings: "60%" },
        { tokens: 2000000, pricePerToken: 0.00195, price: 3900.00, savings: "61%" },
        { tokens: 5000000, pricePerToken: 0.0019, price: 9500.00, savings: "62%" }
    ];

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            // If user is already authenticated, redirect to dashboard
            navigate('/new-project');
        }
    }, [navigate]);

    useEffect(() => {
        const observers = observerRefs.current.map(ref => {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting) {
                        setIsVisible(prev => ({ ...prev, [entry.target.id]: true }));
                    }
                },
                { threshold: 0.1 }
            );

            if (ref) observer.observe(ref);
            return observer;
        });

        return () => observers.forEach(observer => observer.disconnect());
    }, []);

    const ParallaxCard = ({ children, delay = 0 }) => (
        <div
            className="relative bg-white/10 backdrop-blur-lg rounded-2xl p-6 border border-white/10
                      transform transition-all duration-500 hover:scale-105 hover:-rotate-1
                      hover:shadow-[0_0_50px_rgba(59,130,246,0.2)]"
            style={{ animationDelay: `${delay}ms` }}
        >
            {children}
        </div>
    );

    const handleGetStarted = () => {
        navigate('/signup');
    };

    const handleLogin = () => {
        navigate('/login');
    };

//     return (
//         <div className="min-h-screen bg-slate-900 text-white overflow-hidden">
//             {/* Navigation */}
//             <nav className="fixed top-0 left-0 right-0 z-50 bg-slate-900/80 backdrop-blur-lg border-b border-white/10">
//                 <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//                     <div className="flex items-center justify-between h-16">
//                         <div className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-violet-400
//                                       text-transparent bg-clip-text animate-pulse">
//                             Persoline
//                         </div>
//                     </div>
//                 </div>
//             </nav>
//
//             {/* Animated Background */}
//             <div className="fixed inset-0 overflow-hidden -z-10">
//                 <div className="absolute inset-0 bg-gradient-to-br from-blue-600/20 to-violet-600/20" />
//                 <div className="absolute inset-0">
//                     {[...Array(20)].map((_, i) => (
//                         <div
//                             key={i}
//                             className="absolute rounded-full mix-blend-screen filter blur-xl animate-blob"
//                             style={{
//                                 backgroundColor: `rgba(${Math.random() * 255}, ${Math.random() * 255}, 255, 0.15)`,
//                                 top: `${Math.random() * 100}%`,
//                                 left: `${Math.random() * 100}%`,
//                                 width: `${Math.random() * 300 + 100}px`,
//                                 height: `${Math.random() * 300 + 100}px`,
//                                 animationDelay: `${Math.random() * 10}s`,
//                                 animationDuration: `${Math.random() * 20 + 10}s`
//                             }}
//                         />
//                     ))}
//                 </div>
//             </div>
//
//             {/* Hero Section */}
//             <div className="relative">
//                 <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 pb-32">
//                     <div className="text-center mb-20 space-y-8">
//                         <div
//                             className="inline-block text-sm font-medium px-4 py-2 bg-white/10 rounded-full backdrop-blur-sm
//                                      border border-white/10 animate-fade-in-up"
//                         >
//                             🚀 Trusted by 1,000+ businesses worldwide
//                         </div>
//                         <h1 className="text-6xl md:text-7xl font-bold mb-8 animate-fade-in-up"
//                             style={{animationDelay: '200ms'}}>
//                             <span className="block">Unlock </span>
//                             <span
//                                 className="block bg-gradient-to-r from-blue-400 to-violet-400 text-transparent bg-clip-text">
//                                 Personalized Email
//                             </span>
//                             <span className="block">Engagement</span>
//                         </h1>
//                         <p className="text-xl text-slate-300 max-w-2xl mx-auto mb-8 animate-fade-in-up"
//                            style={{animationDelay: '400ms'}}>
//                             Boost open and reply rates with AI-powered, lead-specific messaging –
//                             all seamlessly integrated into your existing workflow.
//                         </p>
//                         <div className="flex justify-center gap-4 animate-fade-in-up" style={{animationDelay: '600ms'}}>
//                             <button
//                                 onClick={handleGetStarted}
//                                 className="group px-8 py-4 bg-gradient-to-r from-blue-600 to-violet-600 rounded-xl
//                          hover:shadow-[0_0_30px_rgba(59,130,246,0.5)] transition-all duration-300
//                          font-medium flex items-center gap-2"
//                             >
//                                 Get Started Free
//                                 <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform"/>
//                             </button>
//                             <button
//                                 onClick={handleLogin}
//                                 className="px-8 py-4 bg-white/10 text-white rounded-xl hover:bg-white/20
//                          transition-colors duration-300 backdrop-blur-sm font-medium
//                          border border-white/10"
//                             >
//                                 Login
//                             </button>
//                         </div>
//                     </div>
//
//                     {/* Stats Section */}
//                     <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-4xl mx-auto">
//                         {[
//                             {label: "Higher Response Rate", value: "3x", icon: BarChart2},
//                             {label: "Emails Personalized", value: "1M+", icon: Mail},
//                             {label: "Customer Satisfaction", value: "98%", icon: Users}
//                         ].map((stat, index) => (
//                             <ParallaxCard key={index} delay={index * 200}>
//                                 <div className="text-center">
//                                     <stat.icon className="w-8 h-8 text-blue-400 mx-auto mb-4"/>
//                                     <div className="text-4xl font-bold bg-gradient-to-r from-blue-400 to-violet-400
//                                                     text-transparent bg-clip-text mb-2">
//                                     {stat.value}
//                                     </div>
//                                     <p className="text-slate-300">{stat.label}</p>
//                                 </div>
//                             </ParallaxCard>
//                         ))}
//                     </div>
//                 </div>
//             </div>
//
//             {/* How It Works Section */}
//             <div className="relative py-32 bg-gradient-to-b from-transparent to-slate-800/50">
//                 <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//                     <div className="text-center mb-20">
//                         <h2 className="text-4xl font-bold mb-4">How It Works</h2>
//                         <p className="text-xl text-slate-300">Three simple steps to personalized outreach</p>
//                     </div>
//
//                     <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
//                         {[
//                             {
//                                 icon: Upload,
//                                 title: "Upload Your Data",
//                                 description: "Simply upload your lead data in CSV or XLSX format"
//                             },
//                             {
//                                 icon: Zap,
//                                 title: "AI Personalization",
//                                 description: "Our AI analyzes and creates custom messages for each lead"
//                             },
//                             {
//                                 icon: Download,
//                                 title: "Download & Send",
//                                 description: "Get your enhanced file with personalized messages ready to use"
//                             }
//                         ].map((feature, index) => (
//                             <div
//                                 key={index}
//                                 ref={el => observerRefs.current[index] = el}
//                                 id={`feature-${index}`}
//                                 className={`transform transition-all duration-1000 ${
//                                     isVisible[`feature-${index}`]
//                                         ? 'translate-y-0 opacity-100'
//                                         : 'translate-y-20 opacity-0'
//                                 }`}
// >
// <ParallaxCard delay={index * 200}>
//     <div className="relative">
//     <div className="h-12 w-12 rounded-xl bg-gradient-to-r from-blue-600 to-violet-600
// flex items-center justify-center mb-6">
// <feature.icon className="h-6 w-6" />
//     </div>
// <h3 className="text-xl font-semibold mb-4">{feature.title}</h3>
// <p className="text-slate-300">{feature.description}</p>
// </div>
// </ParallaxCard>
// </div>
// ))}
// </div>
// </div>
// </div>
//
// {/* AI Section */}
// <div className="relative py-32 bg-gradient-to-b from-slate-900 to-slate-800">
//     <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//         <div className="grid md:grid-cols-2 gap-12 items-center">
//             <div>
//                 <h2 className="text-4xl font-bold mb-6">
//                     Powered by Advanced AI
//                 </h2>
//                 <p className="text-xl text-slate-300 mb-8">
//                     Our sophisticated AI algorithms analyze multiple data points to create
//                     truly personalized messages that resonate with your leads.
//                 </p>
//                 <ul className="space-y-4">
//                     {[
//                         "Context-aware personalization",
//                         "Natural language processing",
//                         // "Industry-specific insights",
//                         "Continuous learning and optimization"
//                     ].map((feature, index) => (
//                         <li key={index} className="flex items-center gap-3">
//                             <div className="h-6 w-6 rounded-full bg-blue-500/20 flex items-center justify-center">
//                                 <CheckCircle2 className="w-4 h-4 text-blue-400" />
//                             </div>
//                             <span className="text-slate-300">{feature}</span>
//                         </li>
//                     ))}
//                 </ul>
//             </div>
//             <div className="relative">
//                 <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-violet-500/20 rounded-3xl
//                                           transform rotate-6 blur-xl animate-pulse" />
//                 <div className="relative bg-slate-800 rounded-2xl border border-white/10 p-6 backdrop-blur-sm">
//                     <div className="space-y-4">
//                         <div className="h-8 bg-slate-700/50 rounded w-3/4 animate-pulse" />
//                         <div className="h-8 bg-slate-700/50 rounded w-1/2 animate-pulse" />
//                         <div className="h-8 bg-slate-700/50 rounded w-2/3 animate-pulse" />
//                         <div className="mt-8 flex gap-3">
//                             <div className="h-10 w-10 rounded bg-blue-500/20 animate-pulse" />
//                             <div className="flex-1">
//                                 <div className="h-6 bg-slate-700/50 rounded w-1/3 mb-2 animate-pulse" />
//                                 <div className="h-4 bg-slate-700/50 rounded w-1/2 animate-pulse" />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </div>
// </div>
//
// {/* Pricing Section */}
// <div className="relative py-24 bg-slate-800/50">
//     <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//         <div className="text-center mb-16">
//             <h2 className="text-4xl font-bold mb-4">Simple, Transparent Pricing</h2>
//             <p className="text-xl text-slate-300">Choose the package that fits your needs</p>
//         </div>
//
//         <div className="bg-white/10 backdrop-blur-lg rounded-2xl border border-white/10 shadow-xl overflow-hidden">
//             <div className="overflow-x-auto">
//                 <table className="w-full">
//                     <thead>
//                     <tr className="border-b border-slate-200">
//                         <th className="py-4 px-6 text-left text-sm font-semibold text-slate-400">Tokens</th>
//                         <th className="py-4 px-6 text-right text-sm font-semibold text-slate-400">Price per Token</th>
//                         <th className="py-4 px-6 text-right text-sm font-semibold text-slate-400">Total Price</th>
//                         <th className="py-4 px-6 text-right text-sm font-semibold text-slate-400">Savings</th>
//                     </tr>
//                     </thead>
//                     <tbody className="divide-y divide-slate-200">
//                     {tokenPackages.map((pkg, index) => (
//                         <tr key={index} className="hover:bg-slate-50/80 transition-colors duration-150">
//                             <td className="py-4 px-6">
//                                 <div className="flex items-center gap-2">
//                                     <Coins className="w-5 h-5 text-blue-500"/>
//                                     <span className="font-medium">{pkg.tokens.toLocaleString()}</span>
//                                 </div>
//                             </td>
//                             <td className="py-4 px-6 text-right font-mono">${pkg.pricePerToken.toFixed(4)}</td>
//                             <td className="py-4 px-6 text-right font-medium">${pkg.price.toFixed(2)}</td>
//                             <td className="py-4 px-6 text-right">
//                                 {pkg.savings !== "0%" && (
//                                     <span className="inline-flex items-center gap-1 text-green-600 font-medium">
//                                                         <Sparkles className="w-4 h-4"/>
//                                                         Save {pkg.savings}
//                                                     </span>
//                                 )}
//                             </td>
//                         </tr>
//                     ))}
//                     </tbody>
//                 </table>
//             </div>
//         </div>
//     </div>
// </div>
//
//             {/* CTA Section */}
//             <div className="relative py-24">
//                 <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//         <div className="relative overflow-hidden rounded-2xl bg-gradient-to-r from-blue-600 to-violet-600 p-12">
//             <div className="absolute inset-0 overflow-hidden">
//                 {[...Array(5)].map((_, i) => (
//                     <div
//                         key={i}
//                         className="absolute rounded-full mix-blend-overlay animate-pulse"
//                         style={{
//                             backgroundColor: 'rgba(255, 255, 255, 0.1)',
//                             top: `${Math.random() * 100}%`,
//                             left: `${Math.random() * 100}%`,
//                             width: `${Math.random() * 200 + 50}px`,
//                             height: `${Math.random() * 200 + 50}px`,
//                             animationDelay: `${i * 0.5}s`
//                         }}
//                     />
//                 ))}
//             </div>
//             <div className="relative text-center">
//                 <h2 className="text-3xl font-bold mb-6">Ready to Transform Your Outreach?</h2>
//                 <p className="text-xl text-blue-100 mb-8 max-w-2xl mx-auto">
//                     Join thousands of businesses using our platform to drive better engagement
//                     through personalization.
//                 </p>
//                 <div className="flex justify-center gap-4">
//                     <Link to="/signup">
//                         <button
//                             onClick={() => navigate('/signup')}
//                             className="px-8 py-4 bg-white text-blue-600 rounded-xl hover:bg-blue-50
//              transition-colors duration-300 font-medium flex items-center gap-2"
//                         >
//                             Claim Your 50 Free Tokens
//                             <ArrowRight className="w-5 h-5"/>
//                         </button>
//                     </Link>
//                 </div>
//             </div>
//         </div>
//                 </div>
//             </div>
//
//             {/* Footer */}
//             <footer className="relative py-12 border-t border-white/10">
//                 <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//                     <div className="flex justify-center items-center gap-8 text-slate-400">
//             <div className="flex items-center gap-2">
//                 <Shield className="w-5 h-5" />
//                 <span className="text-sm">Enterprise-grade security</span>
//             </div>
//             <div className="flex items-center gap-2">
//                 <Globe className="w-5 h-5" />
//                 <span className="text-sm">Available worldwide</span>
//             </div>
//             <div className="flex items-center gap-2">
//                 <Clock className="w-5 h-5" />
//                 <span className="text-sm">24/7 Support</span>
//             </div>
//         </div>
//     </div>
// </footer>
// </div>
// );
    return (
        <div className="min-h-screen bg-slate-900 text-white overflow-hidden">
            {/* Navigation - Made mobile-friendly */}
            <nav className="fixed top-0 left-0 right-0 z-50 bg-slate-900/80 backdrop-blur-lg border-b border-white/10">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-16">
                        <div className="text-xl sm:text-2xl font-bold bg-gradient-to-r from-blue-400 to-violet-400
                                  text-transparent bg-clip-text animate-pulse">
                            Persoline
                        </div>
                    </div>
                </div>
            </nav>

            {/* Animated Background - Kept as is */}
            <div className="fixed inset-0 overflow-hidden -z-10">
                <div className="absolute inset-0 bg-gradient-to-br from-blue-600/20 to-violet-600/20"/>
                <div className="absolute inset-0">
                    {[...Array(20)].map((_, i) => (
                        <div
                            key={i}
                            className="absolute rounded-full mix-blend-screen filter blur-xl animate-blob"
                            style={{
                                backgroundColor: `rgba(${Math.random() * 255}, ${Math.random() * 255}, 255, 0.15)`,
                                top: `${Math.random() * 100}%`,
                                left: `${Math.random() * 100}%`,
                                width: `${Math.random() * 300 + 100}px`,
                                height: `${Math.random() * 300 + 100}px`,
                                animationDelay: `${Math.random() * 10}s`,
                                animationDuration: `${Math.random() * 20 + 10}s`
                            }}
                        />
                    ))}
                </div>
            </div>

            {/* Hero Section - Fixed mobile title */}
            <div className="relative">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 pb-32">
                    <div className="text-center mb-20 space-y-8">
                        <div className="inline-block text-xs sm:text-sm font-medium px-3 sm:px-4 py-2 bg-white/10
                                  rounded-full backdrop-blur-sm border border-white/10 animate-fade-in-up">
                            🚀 Trusted by 1,000+ businesses worldwide
                        </div>

                        {/* Fixed title for mobile */}
                        <h1 className="text-4xl sm:text-6xl md:text-7xl font-bold mb-8 animate-fade-in-up
                                 leading-tight sm:leading-normal px-2 sm:px-0"
                            style={{animationDelay: '200ms'}}>
                            <span className="inline sm:block">Unlock</span>{' '}
                            <span className="inline sm:block bg-gradient-to-r from-blue-400 to-violet-400
                                       text-transparent bg-clip-text">
                            Personalized
                        </span>{' '}
                            <span className="inline sm:block bg-gradient-to-r from-blue-400 to-violet-400
                                       text-transparent bg-clip-text">
                            Email
                        </span>{' '}
                            <span className="inline sm:block">Engagement</span>
                        </h1>

                        <p className="text-lg sm:text-xl text-slate-300 max-w-2xl mx-auto mb-8 animate-fade-in-up px-4 sm:px-0"
                           style={{animationDelay: '400ms'}}>
                            Boost open and reply rates with AI-powered, lead-specific messaging –
                            all seamlessly integrated into your existing workflow.
                        </p>

                        {/* Buttons - Made mobile-friendly */}
                        <div className="flex flex-col sm:flex-row justify-center gap-4 animate-fade-in-up px-4 sm:px-0"
                             style={{animationDelay: '600ms'}}>
                            <button
                                onClick={handleGetStarted}
                                className="w-full sm:w-auto px-6 sm:px-8 py-4 bg-gradient-to-r from-blue-600 to-violet-600
                                     rounded-xl hover:shadow-[0_0_30px_rgba(59,130,246,0.5)] transition-all duration-300
                                     font-medium flex items-center justify-center gap-2"
                            >
                                Get Started Free
                                <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform"/>
                            </button>
                            <button
                                onClick={handleLogin}
                                className="w-full sm:w-auto px-6 sm:px-8 py-4 bg-white/10 text-white rounded-xl
                                     hover:bg-white/20 transition-colors duration-300 backdrop-blur-sm font-medium
                                     border border-white/10"
                            >
                                Login
                            </button>
                        </div>
                    </div>

                    {/* Stats Section */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-4xl mx-auto">
                        {[
                            {label: "Higher Response Rate", value: "3x", icon: BarChart2},
                            {label: "Emails Personalized", value: "1M+", icon: Mail},
                            {label: "Customer Satisfaction", value: "98%", icon: Users}
                        ].map((stat, index) => (
                            <ParallaxCard key={index} delay={index * 200}>
                                <div className="text-center">
                                    <stat.icon className="w-8 h-8 text-blue-400 mx-auto mb-4"/>
                                    <div className="text-4xl font-bold bg-gradient-to-r from-blue-400 to-violet-400
                                                    text-transparent bg-clip-text mb-2">
                                        {stat.value}
                                    </div>
                                    <p className="text-slate-300">{stat.label}</p>
                                </div>
                            </ParallaxCard>
                        ))}
                    </div>
                </div>
            </div>

            {/* How It Works Section */}
            <div className="relative py-32 bg-gradient-to-b from-transparent to-slate-800/50">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="text-center mb-20">
                        <h2 className="text-4xl font-bold mb-4">How It Works</h2>
                        <p className="text-xl text-slate-300">Three simple steps to personalized outreach</p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {[
                            {
                                icon: Upload,
                                title: "Upload Your Data",
                                description: "Simply upload your lead data in CSV or XLSX format"
                            },
                            {
                                icon: Zap,
                                title: "AI Personalization",
                                description: "Our AI analyzes and creates custom messages for each lead"
                            },
                            {
                                icon: Download,
                                title: "Download & Send",
                                description: "Get your enhanced file with personalized messages ready to use"
                            }
                        ].map((feature, index) => (
                            <div
                                key={index}
                                ref={el => observerRefs.current[index] = el}
                                id={`feature-${index}`}
                                className={`transform transition-all duration-1000 ${
                                    isVisible[`feature-${index}`]
                                        ? 'translate-y-0 opacity-100'
                                        : 'translate-y-20 opacity-0'
                                }`}
                            >
                                <ParallaxCard delay={index * 200}>
                                    <div className="relative">
                                        <div className="h-12 w-12 rounded-xl bg-gradient-to-r from-blue-600 to-violet-600
                                                        flex items-center justify-center mb-6">
                                            <feature.icon className="h-6 w-6"/>
                                        </div>
                                        <h3 className="text-xl font-semibold mb-4">{feature.title}</h3>
                                        <p className="text-slate-300">{feature.description}</p>
                                    </div>
                                </ParallaxCard>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* AI Section */}
            <div className="relative py-32 bg-gradient-to-b from-slate-900 to-slate-800">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="grid md:grid-cols-2 gap-12 items-center">
                        <div>
                            <h2 className="text-4xl font-bold mb-6">
                                Powered by Advanced AI
                            </h2>
                            <p className="text-xl text-slate-300 mb-8">
                                Our sophisticated AI algorithms analyze multiple data points to create
                                truly personalized messages that resonate with your leads.
                            </p>
                            <ul className="space-y-4">
                                {[
                                    "Context-aware personalization",
                                    "Natural language processing",
                                    // "Industry-specific insights",
                                    "Continuous learning and optimization"
                                ].map((feature, index) => (
                                    <li key={index} className="flex items-center gap-3">
                                        <div
                                            className="h-6 w-6 rounded-full bg-blue-500/20 flex items-center justify-center">
                                            <CheckCircle2 className="w-4 h-4 text-blue-400"/>
                                        </div>
                                        <span className="text-slate-300">{feature}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="relative">
                            <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-violet-500/20 rounded-3xl
                                          transform rotate-6 blur-xl animate-pulse"/>
                            <div
                                className="relative bg-slate-800 rounded-2xl border border-white/10 p-6 backdrop-blur-sm">
                                <div className="space-y-4">
                                    <div className="h-8 bg-slate-700/50 rounded w-3/4 animate-pulse"/>
                                    <div className="h-8 bg-slate-700/50 rounded w-1/2 animate-pulse"/>
                                    <div className="h-8 bg-slate-700/50 rounded w-2/3 animate-pulse"/>
                                    <div className="mt-8 flex gap-3">
                                        <div className="h-10 w-10 rounded bg-blue-500/20 animate-pulse"/>
                                        <div className="flex-1">
                                            <div className="h-6 bg-slate-700/50 rounded w-1/3 mb-2 animate-pulse"/>
                                            <div className="h-4 bg-slate-700/50 rounded w-1/2 animate-pulse"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Pricing Section - Made mobile-responsive */}
            <div className="relative py-20 sm:py-24 bg-slate-800/50">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="text-center mb-12 sm:mb-16">
                        <h2 className="text-3xl sm:text-4xl font-bold mb-4">Simple, Transparent Pricing</h2>
                        <p className="text-lg sm:text-xl text-slate-300">Choose the package that fits your needs</p>
                    </div>

                    <div
                        className="bg-white/10 backdrop-blur-lg rounded-2xl border border-white/10 shadow-xl overflow-hidden">
                        <div className="overflow-x-auto">
                            <table className="w-full">
                                <thead>
                                <tr className="border-b border-slate-700">
                                    <th className="py-4 px-4 sm:px-6 text-left text-sm font-semibold text-slate-400">
                                        Tokens
                                    </th>
                                    <th className="py-4 px-4 sm:px-6 text-right text-sm font-semibold text-slate-400">
                                        Price per Token
                                    </th>
                                    <th className="py-4 px-4 sm:px-6 text-right text-sm font-semibold text-slate-400">
                                        Total Price
                                    </th>
                                    <th className="hidden sm:table-cell py-4 px-4 sm:px-6 text-right text-sm font-semibold text-slate-400">
                                        Savings
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-slate-700">
                                {tokenPackages.map((pkg, index) => (
                                    <tr key={index} className="hover:bg-slate-700/50 transition-colors duration-150">
                                        <td className="py-4 px-4 sm:px-6">
                                            <div className="flex items-center gap-2">
                                                <Coins className="w-5 h-5 text-blue-500"/>
                                                <span className="font-medium">{pkg.tokens.toLocaleString()}</span>
                                            </div>
                                        </td>
                                        <td className="py-4 px-4 sm:px-6 text-right font-mono">
                                            ${pkg.pricePerToken.toFixed(4)}
                                        </td>
                                        <td className="py-4 px-4 sm:px-6 text-right font-medium">
                                            ${pkg.price.toFixed(2)}
                                        </td>
                                        <td className="hidden sm:table-cell py-4 px-4 sm:px-6 text-right">
                                            {pkg.savings !== "0%" && (
                                                <span
                                                    className="inline-flex items-center gap-1 text-green-400 font-medium">
                                                    <Sparkles className="w-4 h-4"/>
                                                    Save {pkg.savings}
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* CTA Section */}
            <div className="relative py-24">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div
                        className="relative overflow-hidden rounded-2xl bg-gradient-to-r from-blue-600 to-violet-600 p-12">
                        <div className="absolute inset-0 overflow-hidden">
                            {[...Array(5)].map((_, i) => (
                                <div
                                    key={i}
                                    className="absolute rounded-full mix-blend-overlay animate-pulse"
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                        top: `${Math.random() * 100}%`,
                                        left: `${Math.random() * 100}%`,
                                        width: `${Math.random() * 200 + 50}px`,
                                        height: `${Math.random() * 200 + 50}px`,
                                        animationDelay: `${i * 0.5}s`
                                    }}
                                />
                            ))}
                        </div>
                        <div className="relative text-center">
                            <h2 className="text-3xl font-bold mb-6">Ready to Transform Your Outreach?</h2>
                            <p className="text-xl text-blue-100 mb-8 max-w-2xl mx-auto">
                                Join thousands of businesses using our platform to drive better engagement
                                through personalization.
                            </p>
                            <div className="flex justify-center gap-4">
                                <Link to="/signup">
                                    <button
                                        onClick={() => navigate('/signup')}
                                        className="px-8 py-4 bg-white text-blue-600 rounded-xl hover:bg-blue-50
                                                   transition-colors duration-300 font-medium flex items-center gap-2"
                                    >
                                        Claim Your 50 Free Tokens
                                        <ArrowRight className="w-5 h-5"/>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <footer className="relative py-12 border-t border-white/10">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-center items-center gap-8 text-slate-400">
                        <div className="flex items-center gap-2">
                            <Shield className="w-5 h-5"/>
                            <span className="text-sm">Enterprise-grade security</span>
                        </div>
                        <div className="flex items-center gap-2">
                            <Globe className="w-5 h-5"/>
                            <span className="text-sm">Available worldwide</span>
                        </div>
                        <div className="flex items-center gap-2">
                            <Clock className="w-5 h-5"/>
                            <span className="text-sm">24/7 Support</span>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default LandingPage;