// import React, { useState } from 'react';
// import axios from 'axios';
// import { Mail, Lock, ArrowRight } from 'lucide-react';
//
// const Signup = ({ setToken, setIsSignup, setView }) => {
//     const [email, setEmail] = useState('');
//     const [firstName, setFirstName] = useState('');
//     const [lastName, setLastName] = useState('');
//     const [password, setPassword] = useState('');
//     const [confirmPassword, setConfirmPassword] = useState('');
//     const [error, setError] = useState(null);
//     const [loading, setLoading] = useState(false);
//
//     const handleSignup = async (e) => {
//         e.preventDefault();
//         setError(null);
//         setLoading(true);
//
//         if (password !== confirmPassword) {
//             setError("Passwords do not match.");
//             setLoading(false);
//             return;
//         }
//
//         try {
//             const fullName = `${firstName} ${lastName}`;
//             await axios.post('http://127.0.0.1:5001/register', {
//                 email,
//                 full_name: fullName,
//                 password,
//             });
//
//             // After successful registration, immediately log in
//             const loginResponse = await axios.post('http://127.0.0.1:5001/login', {
//                 email,
//                 password,
//             });
//
//             const accessToken = loginResponse.data.access_token;
//             localStorage.setItem('accessToken', accessToken);
//             setToken(accessToken);
//             setView("New Project");
//         } catch (error) {
//             setError(error.response?.data?.error || "Signup failed. Please try again.");
//         } finally {
//             setLoading(false);
//         }
//     };
//
//     return (
//         <div className="min-h-screen flex items-center justify-center bg-slate-50 p-4">
//             <div className="bg-white rounded-xl shadow-sm p-8 w-full max-w-md">
//                 <div className="text-center mb-8">
//                     <h2 className="text-2xl font-bold text-slate-800">Create an Account</h2>
//                     <p className="text-slate-500 mt-2">Enter your details to get started</p>
//                 </div>
//
//                 <form onSubmit={handleSignup} className="space-y-4">
//                     <div className="grid grid-cols-2 gap-4">
//                         <div>
//                             <label className="block text-sm font-medium text-slate-700 mb-1">First Name</label>
//                             <div className="relative">
//                                 <input
//                                     type="text"
//                                     value={firstName}
//                                     onChange={(e) => setFirstName(e.target.value)}
//                                     className="w-full px-4 py-2 rounded-lg border border-slate-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                                     required
//                                 />
//                             </div>
//                         </div>
//                         <div>
//                             <label className="block text-sm font-medium text-slate-700 mb-1">Last Name</label>
//                             <div className="relative">
//                                 <input
//                                     type="text"
//                                     value={lastName}
//                                     onChange={(e) => setLastName(e.target.value)}
//                                     className="w-full px-4 py-2 rounded-lg border border-slate-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                                     required
//                                 />
//                             </div>
//                         </div>
//                     </div>
//
//                     <div>
//                         <label className="block text-sm font-medium text-slate-700 mb-1">Email</label>
//                         <div className="relative">
//                             <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//                                 <Mail className="h-5 w-5 text-slate-400" />
//                             </div>
//                             <input
//                                 type="email"
//                                 value={email}
//                                 onChange={(e) => setEmail(e.target.value)}
//                                 className="w-full pl-10 pr-4 py-2 rounded-lg border border-slate-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                                 required
//                             />
//                         </div>
//                     </div>
//
//                     <div>
//                         <label className="block text-sm font-medium text-slate-700 mb-1">Password</label>
//                         <div className="relative">
//                             <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//                                 <Lock className="h-5 w-5 text-slate-400" />
//                             </div>
//                             <input
//                                 type="password"
//                                 value={password}
//                                 onChange={(e) => setPassword(e.target.value)}
//                                 className="w-full pl-10 pr-4 py-2 rounded-lg border border-slate-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                                 required
//                             />
//                         </div>
//                     </div>
//
//                     <div>
//                         <label className="block text-sm font-medium text-slate-700 mb-1">Confirm Password</label>
//                         <div className="relative">
//                             <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//                                 <Lock className="h-5 w-5 text-slate-400" />
//                             </div>
//                             <input
//                                 type="password"
//                                 value={confirmPassword}
//                                 onChange={(e) => setConfirmPassword(e.target.value)}
//                                 className="w-full pl-10 pr-4 py-2 rounded-lg border border-slate-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                                 required
//                             />
//                         </div>
//                     </div>
//
//                     {error && (
//                         <div className="bg-red-50 text-red-500 p-3 rounded-lg text-sm">
//                             {error}
//                         </div>
//                     )}
//
//                     <button
//                         type="submit"
//                         disabled={loading}
//                         className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-200 flex items-center justify-center"
//                     >
//                         {loading ? (
//                             <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />
//                         ) : (
//                             <>
//                                 Create Account
//                                 <ArrowRight className="ml-2 h-5 w-5" />
//                             </>
//                         )}
//                     </button>
//                 </form>
//
//                 <div className="mt-6 text-center">
//                     <p className="text-slate-600">
//                         Already have an account?{" "}
//                         <button
//                             onClick={() => setIsSignup(false)}
//                             className="text-blue-600 hover:text-blue-700 font-medium"
//                         >
//                             Log in
//                         </button>
//                     </p>
//                 </div>
//             </div>
//         </div>
//     );
// };
//
// export default Signup;

// src/components/Signup.js
// import React, { useState } from 'react';
// import axios from 'axios';
// import { Mail, Lock, ArrowRight } from 'lucide-react';
// import { useNavigate } from 'react-router-dom';
//
// const Signup = ({ setToken }) => {
//     const [email, setEmail] = useState('');
//     const [firstName, setFirstName] = useState('');
//     const [lastName, setLastName] = useState('');
//     const [password, setPassword] = useState('');
//     const [confirmPassword, setConfirmPassword] = useState('');
//     const [error, setError] = useState(null);
//     const [loading, setLoading] = useState(false);
//     const navigate = useNavigate();
//
//     const handleSignup = async (e) => {
//         e.preventDefault();
//         setError(null);
//         setLoading(true);
//
//         if (password !== confirmPassword) {
//             setError("Passwords do not match.");
//             setLoading(false);
//             return;
//         }
//
//         try {
//             const fullName = `${firstName} ${lastName}`;
//             await axios.post('http://127.0.0.1:5001/register', {
//                 email,
//                 full_name: fullName,
//                 password,
//             });
//
//             // Log the user in immediately after signup
//             const loginResponse = await axios.post('http://127.0.0.1:5001/login', {
//                 email,
//                 password,
//             });
//
//             const accessToken = loginResponse.data.access_token;
//             localStorage.setItem('accessToken', accessToken);
//             setToken(accessToken);
//
//             // Redirect to dashboard
//             navigate("/dashboard");
//         } catch (error) {
//             setError(error.response?.data?.error || "Signup failed. Please try again.");
//         } finally {
//             setLoading(false);
//         }
//     };
//
//     return (
//         <div className="min-h-screen flex items-center justify-center bg-slate-50 p-4">
//             <div className="bg-white rounded-xl shadow-sm p-8 w-full max-w-md">
//                 <div className="text-center mb-8">
//                     <h2 className="text-2xl font-bold text-slate-800">Create an Account</h2>
//                     <p className="text-slate-500 mt-2">Enter your details to get started</p>
//                 </div>
//
//                 <form onSubmit={handleSignup} className="space-y-4">
//                     <div className="grid grid-cols-2 gap-4">
//                         <div>
//                             <label className="block text-sm font-medium text-slate-700 mb-1">First Name</label>
//                             <div className="relative">
//                                 <input
//                                     type="text"
//                                     value={firstName}
//                                     onChange={(e) => setFirstName(e.target.value)}
//                                     className="w-full px-4 py-2 rounded-lg border border-slate-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                                     required
//                                 />
//                             </div>
//                         </div>
//                         <div>
//                             <label className="block text-sm font-medium text-slate-700 mb-1">Last Name</label>
//                             <div className="relative">
//                                 <input
//                                     type="text"
//                                     value={lastName}
//                                     onChange={(e) => setLastName(e.target.value)}
//                                     className="w-full px-4 py-2 rounded-lg border border-slate-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                                     required
//                                 />
//                             </div>
//                         </div>
//                     </div>
//
//                     <div>
//                         <label className="block text-sm font-medium text-slate-700 mb-1">Email</label>
//                         <div className="relative">
//                             <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//                                 <Mail className="h-5 w-5 text-slate-400" />
//                             </div>
//                             <input
//                                 type="email"
//                                 value={email}
//                                 onChange={(e) => setEmail(e.target.value)}
//                                 className="w-full pl-10 pr-4 py-2 rounded-lg border border-slate-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                                 required
//                             />
//                         </div>
//                     </div>
//
//                     <div>
//                         <label className="block text-sm font-medium text-slate-700 mb-1">Password</label>
//                         <div className="relative">
//                             <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//                                 <Lock className="h-5 w-5 text-slate-400" />
//                             </div>
//                             <input
//                                 type="password"
//                                 value={password}
//                                 onChange={(e) => setPassword(e.target.value)}
//                                 className="w-full pl-10 pr-4 py-2 rounded-lg border border-slate-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                                 required
//                             />
//                         </div>
//                     </div>
//
//                     <div>
//                         <label className="block text-sm font-medium text-slate-700 mb-1">Confirm Password</label>
//                         <div className="relative">
//                             <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//                                 <Lock className="h-5 w-5 text-slate-400" />
//                             </div>
//                             <input
//                                 type="password"
//                                 value={confirmPassword}
//                                 onChange={(e) => setConfirmPassword(e.target.value)}
//                                 className="w-full pl-10 pr-4 py-2 rounded-lg border border-slate-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                                 required
//                             />
//                         </div>
//                     </div>
//
//                     {error && (
//                         <div className="bg-red-50 text-red-500 p-3 rounded-lg text-sm">
//                             {error}
//                         </div>
//                     )}
//
//                     <button
//                         type="submit"
//                         disabled={loading}
//                         className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-200 flex items-center justify-center"
//                     >
//                         {loading ? (
//                             <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />
//                         ) : (
//                             <>
//                                 Create Account
//                                 <ArrowRight className="ml-2 h-5 w-5" />
//                             </>
//                         )}
//                     </button>
//                 </form>
//
//                 <div className="mt-6 text-center">
//                     <p className="text-slate-600">
//                         Already have an account?{" "}
//                         <a href="/login" className="text-blue-600 hover:text-blue-700 font-medium">
//                             Log in
//                         </a>
//                     </p>
//                 </div>
//             </div>
//         </div>
//     );
// };
//
// export default Signup;

// src/components/Signup.js
// import React, { useState } from 'react';
// import axios from 'axios';
// import { Mail, Lock, ArrowRight } from 'lucide-react';
// import { Link, useNavigate } from 'react-router-dom';
// import { GoogleLogin } from '@react-oauth/google';
//
// const Signup = ({ setToken }) => {
//     const [email, setEmail] = useState('');
//     const [firstName, setFirstName] = useState('');
//     const [lastName, setLastName] = useState('');
//     const [password, setPassword] = useState('');
//     const [confirmPassword, setConfirmPassword] = useState('');
//     const [error, setError] = useState(null);
//     const [loading, setLoading] = useState(false);
//     const navigate = useNavigate();
//
//     const handleSignup = async (e) => {
//         e.preventDefault();
//         setError(null);
//         setLoading(true);
//
//         if (password !== confirmPassword) {
//             setError("Passwords do not match.");
//             setLoading(false);
//             return;
//         }
//
//         try {
//             const fullName = `${firstName} ${lastName}`;
//             await axios.post('http://127.0.0.1:5001/register', {
//                 email,
//                 full_name: fullName,
//                 password,
//             });
//             navigate("/new-project");  // Navigate to the dashboard after signup
//         } catch (error) {
//             setError(error.response?.data?.error || "Signup failed. Please try again.");
//         } finally {
//             setLoading(false);
//         }
//     };
//
//     const handleGoogleSuccess = async (credentialResponse) => {
//         try {
//             const { data } = await axios.post(
//                 'http://127.0.0.1:5001/login/google',
//                 {},  // empty body
//                 {
//                     headers: {
//                         'Authorization': `Bearer ${credentialResponse.credential}`
//                     }
//                 }
//             );
//             setToken(data.access_token);
//             navigate("/new-project");
//         } catch (error) {
//             console.error('Google login error:', error);
//             setError("Google login failed. Please try again.");
//         }
//     };
//
//     const handleGoogleFailure = () => {
//         setError("Google login was unsuccessful. Please try again.");
//     };
//
//     return (
//         <div className="min-h-screen flex items-center justify-center bg-slate-50 p-4">
//             <div className="bg-white rounded-xl shadow-sm p-8 w-full max-w-md">
//                 <div className="text-center mb-8">
//                     <h2 className="text-2xl font-bold text-slate-800">Create an Account</h2>
//                     <p className="text-slate-500 mt-2">Enter your details to get started</p>
//                 </div>
//
//                 <form onSubmit={handleSignup} className="space-y-4">
//                     <div className="grid grid-cols-2 gap-4">
//                         <div>
//                             <label className="block text-sm font-medium text-slate-700 mb-1">First Name</label>
//                             <div className="relative">
//                                 <input
//                                     type="text"
//                                     value={firstName}
//                                     onChange={(e) => setFirstName(e.target.value)}
//                                     className="w-full px-4 py-2 rounded-lg border border-slate-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                                     required
//                                 />
//                             </div>
//                         </div>
//                         <div>
//                             <label className="block text-sm font-medium text-slate-700 mb-1">Last Name</label>
//                             <div className="relative">
//                                 <input
//                                     type="text"
//                                     value={lastName}
//                                     onChange={(e) => setLastName(e.target.value)}
//                                     className="w-full px-4 py-2 rounded-lg border border-slate-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                                     required
//                                 />
//                             </div>
//                         </div>
//                     </div>
//
//                     <div>
//                         <label className="block text-sm font-medium text-slate-700 mb-1">Email</label>
//                         <div className="relative">
//                             <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//                                 <Mail className="h-5 w-5 text-slate-400"/>
//                             </div>
//                             <input
//                                 type="email"
//                                 value={email}
//                                 onChange={(e) => setEmail(e.target.value)}
//                                 className="w-full pl-10 pr-4 py-2 rounded-lg border border-slate-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                                 required
//                             />
//                         </div>
//                     </div>
//
//                     <div>
//                         <label className="block text-sm font-medium text-slate-700 mb-1">Password</label>
//                         <div className="relative">
//                             <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//                                 <Lock className="h-5 w-5 text-slate-400"/>
//                             </div>
//                             <input
//                                 type="password"
//                                 value={password}
//                                 onChange={(e) => setPassword(e.target.value)}
//                                 className="w-full pl-10 pr-4 py-2 rounded-lg border border-slate-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                                 required
//                             />
//                         </div>
//                     </div>
//
//                     <div>
//                         <label className="block text-sm font-medium text-slate-700 mb-1">Confirm Password</label>
//                         <div className="relative">
//                             <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//                                 <Lock className="h-5 w-5 text-slate-400"/>
//                             </div>
//                             <input
//                                 type="password"
//                                 value={confirmPassword}
//                                 onChange={(e) => setConfirmPassword(e.target.value)}
//                                 className="w-full pl-10 pr-4 py-2 rounded-lg border border-slate-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                                 required
//                             />
//                         </div>
//                     </div>
//
//                     {error && (
//                         <div className="bg-red-50 text-red-500 p-3 rounded-lg text-sm">
//                             {error}
//                         </div>
//                     )}
//
//                     <button
//                         type="submit"
//                         disabled={loading}
//                         className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-200 flex items-center justify-center"
//                     >
//                         {loading ? (
//                             <div
//                                 className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin"/>
//                         ) : (
//                             <>
//                                 Create Account
//                                 <ArrowRight className="ml-2 h-5 w-5"/>
//                             </>
//                         )}
//                     </button>
//                 </form>
//
//                 {/* Google Signup */}
//                 <div className="my-4 text-center">
//                     <p className="text-sm text-slate-600">Or sign up with</p>
//                     <GoogleLogin onSuccess={handleGoogleSuccess} onFailure={handleGoogleFailure}/>
//                 </div>
//
//                 <div className="mt-6 text-center">
//                     <p>Already have an account? <Link to="/login">Log in</Link></p>
//                 </div>
//             </div>
//         </div>
//     );
// };
//
// export default Signup;

import React, { useState } from 'react';
import { Mail, Lock, ArrowRight } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import api from '../axiosConfig';  // Update this path based on your file structure

const Signup = ({ setToken }) => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSignup = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            setLoading(false);
            return;
        }

        try {
            const fullName = `${firstName} ${lastName}`;
            const response = await api.post('/register', {
                email,
                full_name: fullName,
                password,
            });

            // Store the token and update state
            const token = response.data.access_token;
            localStorage.setItem('token', token);
            setToken(token);

            navigate("/new-project");
        } catch (error) {
            console.error('Signup error:', error);
            setError(error.response?.data?.error || "Signup failed. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleSuccess = async (credentialResponse) => {
        try {
            const { data } = await api.post(
                '/login/google',
                {},
                {
                    headers: {
                        'Authorization': `Bearer ${credentialResponse.credential}`
                    }
                }
            );
            const token = data.access_token;
            localStorage.setItem('token', token);
            setToken(token);
            navigate("/new-project");
        } catch (error) {
            console.error('Google login error:', error);
            setError("Google login failed. Please try again.");
        }
    };

    const handleGoogleFailure = () => {
        setError("Google login was unsuccessful. Please try again.");
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-slate-50 p-4">
            <div className="bg-white rounded-xl shadow-sm p-8 w-full max-w-md">
                <div className="text-center mb-8">
                    <h2 className="text-2xl font-bold text-slate-800">Create an Account</h2>
                    <p className="text-slate-500 mt-2">Enter your details to get started</p>
                </div>

                <form onSubmit={handleSignup} className="space-y-4">
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-slate-700 mb-1">First Name</label>
                            <div className="relative">
                                <input
                                    type="text"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    className="w-full px-4 py-2 rounded-lg border border-slate-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                    placeholder="John"
                                    required
                                />
                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-slate-700 mb-1">Last Name</label>
                            <div className="relative">
                                <input
                                    type="text"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    className="w-full px-4 py-2 rounded-lg border border-slate-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                    placeholder="Doe"
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-slate-700 mb-1">Email</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <Mail className="h-5 w-5 text-slate-400"/>
                            </div>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full pl-10 pr-4 py-2 rounded-lg border border-slate-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                placeholder="your@email.com"
                                required
                            />
                        </div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-slate-700 mb-1">Password</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <Lock className="h-5 w-5 text-slate-400"/>
                            </div>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="w-full pl-10 pr-4 py-2 rounded-lg border border-slate-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                placeholder="••••••••"
                                required
                                minLength={6}
                            />
                        </div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-slate-700 mb-1">Confirm Password</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <Lock className="h-5 w-5 text-slate-400"/>
                            </div>
                            <input
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="w-full pl-10 pr-4 py-2 rounded-lg border border-slate-200 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                placeholder="••••••••"
                                required
                                minLength={6}
                            />
                        </div>
                    </div>

                    {error && (
                        <div className="bg-red-50 text-red-500 p-3 rounded-lg text-sm">
                            {error}
                        </div>
                    )}

                    <button
                        type="submit"
                        disabled={loading}
                        className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-200 flex items-center justify-center"
                    >
                        {loading ? (
                            <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin"/>
                        ) : (
                            <>
                                Create Account
                                <ArrowRight className="ml-2 h-5 w-5"/>
                            </>
                        )}
                    </button>
                </form>

                {/* Google Signup */}
                <div className="my-4 text-center">
                    <p className="text-sm text-slate-600">Or sign up with</p>
                    <div className="mt-2">
                        <GoogleLogin
                            onSuccess={handleGoogleSuccess}
                            onError={handleGoogleFailure}
                            useOneTap
                            shape="rectangular"
                            theme="filled_blue"
                        />
                    </div>
                </div>

                <div className="mt-6 text-center">
                    <p className="text-slate-600">
                        Already have an account?{' '}
                        <Link to="/login" className="text-blue-600 hover:text-blue-700 font-medium">
                            Log in
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Signup;