// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import api from '../axiosConfig';
// import {
//     Coins, CreditCard, Check, Sparkles,
//     Shield, PlusCircle
// } from 'lucide-react';
//
// const Credits = () => {
//     const [selectedPackage, setSelectedPackage] = useState(null);
//     const [isProcessing, setIsProcessing] = useState(false);
//     const navigate = useNavigate();
//
//     const tokenPackages = [
//         { tokens: 200, pricePerToken: 0.005, price: 1.00, savings: "0%" }, // New $1 package
//         { tokens: 2000, pricePerToken: 0.005, price: 10.00, savings: "0%" },
//         { tokens: 5000, pricePerToken: 0.004, price: 20.00, savings: "20%" },
//         { tokens: 10000, pricePerToken: 0.0035, price: 35.00, savings: "30%" },
//         { tokens: 25000, pricePerToken: 0.003, price: 75.00, savings: "40%" },
//         { tokens: 50000, pricePerToken: 0.0028, price: 140.00, savings: "44%" },
//         { tokens: 100000, pricePerToken: 0.0025, price: 250.00, savings: "50%" },
//         { tokens: 250000, pricePerToken: 0.0023, price: 575.00, savings: "54%" },
//         { tokens: 500000, pricePerToken: 0.0021, price: 1050.00, savings: "58%" },
//         { tokens: 1000000, pricePerToken: 0.002, price: 2000.00, savings: "60%" },
//         { tokens: 2000000, pricePerToken: 0.00195, price: 3900.00, savings: "61%" },
//         { tokens: 5000000, pricePerToken: 0.0019, price: 9500.00, savings: "62%" }
//     ];
//
//     const handleProceedToCheckout = () => {
//         if (!selectedPackage) {
//             alert("Please select a token package.");
//             return;
//         }
//         setIsProcessing(true);
//         navigate('/checkout', {
//             state: {
//                 package: selectedPackage
//             }
//         });
//     };
//
//     const formatNumber = (num) => {
//         return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//     };
//
//     return (
//         <div className="max-w-7xl mx-auto p-6">
//             <div className="bg-white rounded-xl shadow-sm">
//                 <div className="grid md:grid-cols-2">
//                     {/* Left Panel - Summary and Payment */}
//                     <div className="p-8 border-r border-slate-100">
//                         <div className="max-w-md mx-auto">
//                             <h2 className="text-2xl font-bold text-slate-800 mb-6">Purchase Summary</h2>
//
//                             {selectedPackage ? (
//                                 <div className="space-y-6">
//                                     {/* Token Amount */}
//                                     <div className="bg-blue-50 p-6 rounded-xl">
//                                         <div className="flex items-center justify-between mb-2">
//                                             <span className="text-slate-600">You'll receive</span>
//                                             <div className="flex items-center text-blue-600">
//                                                 <Coins className="w-5 h-5 mr-2" />
//                                                 <span className="text-2xl font-bold">
//                                                     {formatNumber(selectedPackage.tokens)}
//                                                 </span>
//                                             </div>
//                                         </div>
//                                         <div className="flex items-center justify-between">
//                                             <span className="text-slate-600">Price per token</span>
//                                             <span className="text-slate-800 font-medium">
//                                                 ${selectedPackage.pricePerToken.toFixed(4)}
//                                             </span>
//                                         </div>
//                                     </div>
//
//                                     {/* Savings Badge */}
//                                     {selectedPackage.savings !== "0%" && (
//                                         <div className="flex items-center gap-2 text-green-600 bg-green-50 px-4 py-2 rounded-lg">
//                                             <Sparkles className="w-4 h-4" />
//                                             <span className="text-sm font-medium">
//                                                 You save {selectedPackage.savings} with this package!
//                                             </span>
//                                         </div>
//                                     )}
//
//                                     {/* Total Cost */}
//                                     <div className="border-t pt-4">
//                                         <div className="flex items-center justify-between text-lg">
//                                             <span className="font-medium text-slate-700">Total Cost</span>
//                                             <span className="font-bold text-slate-900">
//                                                 ${selectedPackage.price.toFixed(2)}
//                                             </span>
//                                         </div>
//                                     </div>
//
//                                     {/* Payment Button */}
//                                     <button
//                                         onClick={handleProceedToCheckout}
//                                         disabled={isProcessing}
//                                         className="w-full bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 flex items-center justify-center gap-2 disabled:opacity-70 disabled:cursor-not-allowed"
//                                     >
//                                         {isProcessing ? (
//                                             <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />
//                                         ) : (
//                                             <>
//                                                 <CreditCard className="w-5 h-5" />
//                                                 Proceed to Checkout
//                                             </>
//                                         )}
//                                     </button>
//
//                                     {/* Security Notice */}
//                                     <div className="flex items-center gap-2 text-sm text-slate-500 justify-center mt-4">
//                                         <Shield className="w-4 h-4" />
//                                         <span>Secure payment processing by Stripe</span>
//                                     </div>
//                                 </div>
//                             ) : (
//                                 <div className="text-center py-12 text-slate-500">
//                                     <PlusCircle className="w-12 h-12 mx-auto mb-4" />
//                                     <p>Select a package to see the summary</p>
//                                 </div>
//                             )}
//                         </div>
//                     </div>
//
//                     {/* Right Panel - Package Selection */}
//                     <div className="p-8 bg-slate-50 max-h-[800px] flex flex-col">
//                         <h3 className="text-xl font-semibold text-slate-800 mb-6">Select Token Package</h3>
//                         <div className="overflow-y-auto pr-2 space-y-3 scrollbar-thin scrollbar-thumb-slate-200 scrollbar-track-slate-50">
//                             {tokenPackages.map((pkg, index) => (
//                                 <div
//                                     key={index}
//                                     onClick={() => setSelectedPackage(pkg)}
//                                     className={`
//                                         cursor-pointer rounded-lg p-4 transition-all duration-200
//                                         ${selectedPackage === pkg
//                                         ? 'bg-white border-2 border-blue-500 shadow-sm'
//                                         : 'bg-white border border-slate-200 hover:border-blue-200'}
//                                     `}
//                                 >
//                                     <div className="flex items-center justify-between">
//                                         <div>
//                                             <span className="font-medium text-slate-900">
//                                                 {formatNumber(pkg.tokens)} tokens
//                                             </span>
//                                             <div className="text-sm text-slate-500">
//                                                 ${pkg.pricePerToken.toFixed(4)} per token
//                                             </div>
//                                         </div>
//                                         <div className="flex items-center gap-3">
//                                             {pkg.savings !== "0%" && (
//                                                 <span className="text-green-600 text-sm font-medium">
//                                                     Save {pkg.savings}
//                                                 </span>
//                                             )}
//                                             <span className="font-bold text-slate-900">
//                                                 ${pkg.price.toFixed(2)}
//                                             </span>
//                                             {selectedPackage === pkg && (
//                                                 <Check className="w-5 h-5 text-blue-500" />
//                                             )}
//                                         </div>
//                                     </div>
//                                 </div>
//                             ))}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };
//
// export default Credits;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../axiosConfig';
import {
    Coins, CreditCard, Check, Sparkles,
    Shield, PlusCircle
} from 'lucide-react';

const Credits = () => {
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const navigate = useNavigate();

    // Calculate price including Stripe fees (3.9% + $0.30 for international)
    const calculatePriceWithFees = (basePrice) => {
        return Math.ceil(((basePrice + 0.30) / (1 - 0.039)) * 100) / 100;
    };

    const tokenPackages = [
        // {
        //     tokens: 200,
        //     pricePerToken: 0.005,
        //     price: 1.00,
        //     priceWithFees: calculatePriceWithFees(1.00),
        //     savings: "0%"
        // },
        {
            tokens: 2000,
            pricePerToken: 0.005,
            price: 10.00,
            priceWithFees: calculatePriceWithFees(10.00),
            savings: "0%"
        },
        {
            tokens: 5000,
            pricePerToken: 0.004,
            price: 20.00,
            priceWithFees: calculatePriceWithFees(20.00),
            savings: "20%"
        },
        {
            tokens: 10000,
            pricePerToken: 0.0035,
            price: 35.00,
            priceWithFees: calculatePriceWithFees(35.00),
            savings: "30%"
        },
        {
            tokens: 25000,
            pricePerToken: 0.003,
            price: 75.00,
            priceWithFees: calculatePriceWithFees(75.00),
            savings: "40%"
        },
        {
            tokens: 50000,
            pricePerToken: 0.0028,
            price: 140.00,
            priceWithFees: calculatePriceWithFees(140.00),
            savings: "44%"
        },
        {
            tokens: 100000,
            pricePerToken: 0.0025,
            price: 250.00,
            priceWithFees: calculatePriceWithFees(250.00),
            savings: "50%"
        },
        {
            tokens: 250000,
            pricePerToken: 0.0023,
            price: 575.00,
            priceWithFees: calculatePriceWithFees(575.00),
            savings: "54%"
        },
        {
            tokens: 500000,
            pricePerToken: 0.0021,
            price: 1050.00,
            priceWithFees: calculatePriceWithFees(1050.00),
            savings: "58%"
        },
        {
            tokens: 1000000,
            pricePerToken: 0.002,
            price: 2000.00,
            priceWithFees: calculatePriceWithFees(2000.00),
            savings: "60%"
        },
        {
            tokens: 2000000,
            pricePerToken: 0.00195,
            price: 3900.00,
            priceWithFees: calculatePriceWithFees(3900.00),
            savings: "61%"
        },
        {
            tokens: 5000000,
            pricePerToken: 0.0019,
            price: 9500.00,
            priceWithFees: calculatePriceWithFees(9500.00),
            savings: "62%"
        }
    ];

    const handleProceedToCheckout = () => {
        if (!selectedPackage) {
            alert("Please select a token package.");
            return;
        }
        setIsProcessing(true);
        navigate('/checkout', {
            state: {
                package: selectedPackage
            }
        });
    };

    const formatNumber = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return (
        <div className="max-w-7xl mx-auto p-6">
            <div className="bg-white rounded-xl shadow-sm">
                <div className="grid md:grid-cols-2">
                    {/* Left Panel - Summary and Payment */}
                    <div className="p-8 border-r border-slate-100">
                        <div className="max-w-md mx-auto">
                            <h2 className="text-2xl font-bold text-slate-800 mb-6">Purchase Summary</h2>

                            {selectedPackage ? (
                                <div className="space-y-6">
                                    {/* Token Amount */}
                                    <div className="bg-blue-50 p-6 rounded-xl">
                                        <div className="flex items-center justify-between mb-2">
                                            <span className="text-slate-600">You'll receive</span>
                                            <div className="flex items-center text-blue-600">
                                                <Coins className="w-5 h-5 mr-2" />
                                                <span className="text-2xl font-bold">
                                                    {formatNumber(selectedPackage.tokens)} tokens
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <span className="text-slate-600">Price per token</span>
                                            <span className="text-slate-800 font-medium">
                                                ${selectedPackage.pricePerToken.toFixed(4)}
                                            </span>
                                        </div>
                                    </div>

                                    {/* Savings Badge */}
                                    {selectedPackage.savings !== "0%" && (
                                        <div className="flex items-center gap-2 text-green-600 bg-green-50 px-4 py-2 rounded-lg">
                                            <Sparkles className="w-4 h-4" />
                                            <span className="text-sm font-medium">
                                                You save {selectedPackage.savings} with this package!
                                            </span>
                                        </div>
                                    )}

                                    {/* Total Cost */}
                                    <div className="border-t pt-4">
                                        <div className="flex items-center justify-between text-lg">
                                            <span className="font-medium text-slate-700">Total Cost</span>
                                            <span className="font-bold text-slate-900">
                                                ${selectedPackage.priceWithFees.toFixed(2)}
                                            </span>
                                        </div>
                                        <div className="text-sm text-slate-500 mt-1">
                                            Includes processing fees
                                        </div>
                                    </div>

                                    {/* Payment Button */}
                                    <button
                                        onClick={handleProceedToCheckout}
                                        disabled={isProcessing}
                                        className="w-full bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 flex items-center justify-center gap-2 disabled:opacity-70 disabled:cursor-not-allowed"
                                    >
                                        {isProcessing ? (
                                            <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />
                                        ) : (
                                            <>
                                                <CreditCard className="w-5 h-5" />
                                                Pay ${selectedPackage.priceWithFees.toFixed(2)}
                                            </>
                                        )}
                                    </button>

                                    {/* Security Notice */}
                                    <div className="flex items-center gap-2 text-sm text-slate-500 justify-center mt-4">
                                        <Shield className="w-4 h-4" />
                                        <span>Secure payment processing by Stripe</span>
                                    </div>
                                </div>
                            ) : (
                                <div className="text-center py-12 text-slate-500">
                                    <PlusCircle className="w-12 h-12 mx-auto mb-4" />
                                    <p>Select a package to see the summary</p>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Right Panel - Package Selection */}
                    <div className="p-8 bg-slate-50 max-h-[800px] flex flex-col">
                        <h3 className="text-xl font-semibold text-slate-800 mb-6">Select Token Package</h3>
                        <div className="overflow-y-auto pr-2 space-y-3 scrollbar-thin scrollbar-thumb-slate-200 scrollbar-track-slate-50">
                            {tokenPackages.map((pkg, index) => (
                                <div
                                    key={index}
                                    onClick={() => setSelectedPackage(pkg)}
                                    className={`
                                        cursor-pointer rounded-lg p-4 transition-all duration-200
                                        ${selectedPackage === pkg
                                        ? 'bg-white border-2 border-blue-500 shadow-sm'
                                        : 'bg-white border border-slate-200 hover:border-blue-200'}
                                    `}
                                >
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <span className="font-medium text-slate-900">
                                                {formatNumber(pkg.tokens)} tokens
                                            </span>
                                            <div className="text-sm text-slate-500">
                                                ${pkg.pricePerToken.toFixed(4)} per token
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-3">
                                            {pkg.savings !== "0%" && (
                                                <span className="text-green-600 text-sm font-medium">
                                                    Save {pkg.savings}
                                                </span>
                                            )}
                                            <span className="font-bold text-slate-900">
                                                ${pkg.price.toFixed(2)}
                                            </span>
                                            {selectedPackage === pkg && (
                                                <Check className="w-5 h-5 text-blue-500" />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Credits;